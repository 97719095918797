import ModalResumo from '../components/ModalResumo.js'
import SearchFundacao from '../components/SearchFundacao.js'
import TableAtendimento from '../components/TableAtendimento.js'
import AvatarPersona from '../components/AvatarPersona.js'
import acessos from '../mixins/acessos'
import ConfirmModal from '../components/ConfirmModal.js'
import TaskItem from '../components/TaskItem.js'
import NewTaskItem from '../components/NewTaskItem.js'
import ModalSendEmail from '../components/ModalSendEmail.js'

import sendEmail from '../mixins/sendEmail.js'

const {
	generateRange,
	showToastify,
	api,
	errorDefault,
	initTipy,
	FormatREALBackEnd,
	getChoicesActive,
	addClassForTime,
	sortedArray,
} = GLOBAL

const arrPlaceholder = generateRange(8, {
	id: 1,
	politica: 1,
	nome: 'Adelino José Jacques Gambôa Fernandez de Oliveira Netto',
	placeholder: true,
})

let userLogado = document.getElementById('usuario-logado').value

export default {
	mixins: [acessos, sendEmail],

	components: {
		ModalResumo,
		TableAtendimento,
		AvatarPersona,
		ConfirmModal,
		TaskItem,
		NewTaskItem,
		SearchFundacao,
		ModalSendEmail,
	},

	data() {
		return {
			atendimentos: arrPlaceholder,
			data_inicial: '',
			data_inicial_politica: '',
			data_final: '',
			data_final_politica: '',
			dataInicioFormatted: '',
			dataFinalFormatted: '',
			prospect: [],
			atendimentosQuentes: [],
			modalConfirm: {},
			haveModalResumo: true,
			chartHomeLoading: true,
			abatask: false,
			novaTaskOpen: false,
			tarefas: [],
			totalPage: [],
			timingSearch: null,
			status: {
				atrasado: 0,
				pendente: 0, 
				realizado: 0
			},
			pagination: {
				elementPerPage: 10,
				page: 1,
				totalPage: 1,
				next: '',
				previous: '',
			},
			stateSave: {
				searchs: {
					searchFilterAtendimentos: (arr) => arr,
					searchFilterProspect: (arr) => arr,
					// ordenateFilterTarefas: (arr) => arr
				},
			},
		}
	},

	watch: {
		// tarefas: {
		// 	handler(oldValue, newValue) {
		// 	},
		// 	deep: true
		// }
		data_inicial() {
			if (this.data_inicial !== 'DD/MM/YYYY') {
				
				this.formatDate('data_inicial');
			}
			
		},
		data_final() {
			if (this.data_final !== 'DD/MM/YYYY') {
				
				this.formatDate('data_final');
			}
			
		},
		data_inicial_politica() {
			if (this.data_inicial_politica !== 'DD/MM/YYYY') {
				
				this.formatDate('data_inicial_politica');
			}
		
			
		},
		data_final_politica() {
			if (this.data_final_politica !== 'DD/MM/YYYY') {
				
				this.formatDate('data_final_politica');
			}
			
		},
		
	
	},

	computed: {
		atendimentosView() {
			return this.stateSave.searchs.searchFilterAtendimentos(this.atendimentos)
		},

		prospectsView() {
			return this.stateSave.searchs.searchFilterProspect(this.prospect)
		},

		// tarefasView() {
		// 	return this.stateSave.searchs.ordenateFilterTarefas(this.tarefas)
		// },

		numeroAtendimentos() {
			console.log( {
				total:  this.status.pendente + this.status.atrasado + this.status.realizado,
				pendentes: this.status.pendente,
				atrasados: this.status.atrasado,
				realizados: this.status.realizado,
			})
			return {
				total: this.status.pendente + this.status.atrasado + this.status.realizado,
				pendentes: this.status.pendente,
				atrasados: this.status.atrasado,
				realizados: this.status.realizado,
			}
		},

		numeroTarefas() {
			return {
				total: this.isFundacao
					? this.tarefas.filter((task) => task.fundacao).length
					: this.tarefas.filter((task) => task.cliente_pf).length || 0,

				realizados: this.tarefas.filter((i) => i.status == '2').length || 0,
			}
		},
	},

	methods: {

		formatDate(date) {
      if (this[date]) {
        const [year, month, day] = this[date].split('/');
        this.dataInicioFormatted = `${day}/${month}/${year}`;
      } else {
        this.dataInicioFormatted = '';
      }
		},
		formatDateFinal() {
      if (this.data_final) {
        const [year, month, day] = this.data_final.split('/');
        this.dataFinalFormatted = `${day}/${month}/${year}`;
      } else {
        this.dataFinalFormatted = '';
      }
    },
		//!!! Modal methods Home
		modalOpen(item) {

			this.$refs.modalResumo.OpenModalResumo(item)
		},

		modalOpenWithAtendimento(item) {
			this.$refs.modalResumo.modalOpenAtendimento(item)
		},

		handleOpenModalConfirmHome(data) {
			this.$refs.modalResumo.handleOpenModalConfirm(item)
		},
		totalPageCalcula() {
			let array = Array.from(Array(this.pagination.totalPage), (e, i) => i + 1)

			this.totalPage = array
		},

		openModalProspectHome(item) {
			this.$refs.modalResumo.openModalProspect(item)
		},

		openModalCancelAtendimentoQuenteHome(item) {
			this.$refs.modalResumo.openModalCancelAtendimentoQuente(item)
		},
		//!!! modal Confirm Home
		openModalProspect(prospect) {
			const nameCurto = prospect.nome.split(' ')[0]

			this.modalConfirm = {
				open: true,
				text: `Deseja converter <b class="text-primary-pure">${nameCurto}</b> em um cliente `,
				data: prospect,
				id: 'prospect',
			}
		},
		selectRentabilidade(type, client) {
			this.$refs.modalResumo.selectRentabilidade(type, client)
		},
		//-------------------------------------------

		//!!! GET  🍃 Home
		async getAtendimentos() {
			try {
				//console.log('essa')
					const dataInicialPoliticaFormated = this.data_inicial_politica.split('/').reverse().join('-')
				const dataFinalPoliticaFormated = this.data_final_politica.split('/').reverse().join('-')
				const end = this.isFundacao
					? '?exibicao=fundacao'
					: '?exibicao=cliente_pf'
				const request = await api.get(`/api/atendimentos/${end}&data_inicial=${dataInicialPoliticaFormated}&data_final=${dataFinalPoliticaFormated}`)
				const data = await request.data.results

				this.atendimentos = data?.atendimentos
				this.status  = data?.status
			
				this.isFundacao && this.initChartRealizados()
				setTimeout(() => {
					initTipy()
				}, 150)
			} catch (e) {
				errorDefault(e)
			}
		},

		async getProspect(paramUrl) {
			try {
				const url = paramUrl ?? `/api/clientepf/?prospect=true`

				const request = await api.get(url)
			
				this.paginationChange(request.data)
				const data = await request.data.results
				this.prospect = data

				setTimeout(() => initTipy(), 150)
			} catch (e) {
				errorDefault(e)
			}
		},

		paginationChange(data) {
			this.pagination = {
				...this.pagination,
				elementPerPage: 10,
				totalPage: Math.ceil(data.count / this.pagination.elementPerPage),
				next: data.next,
				previous: data.previous,
			}
		
			this.totalPageCalcula()
		},

		async next() {
			await this.getProspect(this.pagination.next)
			this.pagination.page = this.pagination.page + 1
		
		},

		async previous() {
			await this.getProspect(this.pagination.previous)
			this.pagination.page = this.pagination.page - 1
			
		},

		async clickPage(page) {
			await this.getProspect(`/api/clientepf/?prospect=true&page=${page}`)
			this.pagination.page = page
		},

		async getAtendimentosQuentes() {
			try {
				const url = this.isFundacao
					? `/api/fundacao/?atendimento_quente=true`
					: `/api/clientepf/?atendimento_quente=true`

				const request = await api.get(url)
				const data = await request.data.results
				this.atendimentosQuentes = data

				setTimeout(() => initTipy(), 150)
			} catch (e) {
				errorDefault(e)
			}
		},
		//--------------------------------------------
		//!!! SENDS HOME

		async sendProspectFalse(prospect) {
			const obj = {
				prospect: false,
			}

			try {
				const request2 = await axios.patch(`/api/clientepf/${prospect.id}/`, {
					...obj,
				})
				await this.getProspect()

				const nameCurto = this.isFundacao
					? request2.data.sigla
					: request2.data.nome.split(' ')[0]

				showToastify(`✅ ${nameCurto} foi convertido(a) em cliente.`)
				this.modalConfirm = {}
			} catch (e) {
				errorDefault(e)
			}
		},

		async sendNewProspect(e) {
			e.preventDefault()

			const form = document.querySelector('#form_novo_prospect')

			if (!form.checkValidity()) {
				form
					.querySelectorAll('.field')
					.forEach((i) => addClassForTime(i, 'required-field', 3000))

				showToastify('⚠️ Preencha todos os campos obrigatórios para enviar')

				return
			} else {
				const fields = form.querySelectorAll('[name]')

				const obj = Array.from(fields).reduce((acc, { name, value }) => {
					const valueLimpo =
						name == 'patrimonio_externo'
							? value
								? FormatREALBackEnd(value)
								: 0
							: value
					acc[name] = valueLimpo
					return acc
				}, {})

				obj.prospect = true
				obj.usuario_responsavel = [userLogado] //necessario enviar um array

				try {
					NProgress.start()
					const request = await axios.post(`/api/clientepf/`, obj)

					await this.getProspect()
					showToastify('✅ Prospect criado com sucesso')
					modal_padrao.closeModal()

					// if (this.clienteAtivo.id) {
					// 	await this.getDadosModal(this.clienteAtivo.id, true);

					// }
				} catch (e) {
					errorDefault(e)
				} finally {
					NProgress.done()
				}
			}
		},

		async sendNewTask(obj) {
			const { data } = await this.$refs.modalResumo.sendNewTask(obj)
			this.activeAbaTask(false)
			this.novaTaskOpen = false
			setTimeout(() => {
				const el = document.querySelector(`.item-task[data-id='${data.id}']`)
				el && addClassForTime(el, '!border-primary-pure', 3000)
				el && el.scrollIntoView({ behavior: 'smooth', block: 'start' })
			
			}, 300)
		},

		async taskEnviadaSucess(text) {
			await this.activeAbaTask(false)
			showToastify(text)
		},

		//-----------------------------------------------
		searchInHome(e) {
			if (!this.stateSave.inputActive) {
				this.stateSave.atendimentosHome = this.atendimentos
				this.stateSave.inputActive = true
			}
			const target = e.target
			const value = target.value

			if (value != '') {
				this.atendimentosView =
					this.stateSave.searchs.searchFilterAtendimentos = (arr) =>
						arr?.filter((i) =>
							(this.isFundacao ? i.nome_fundacao : i.nome_cliente_pf)
								.toLowerCase()
								.includes(value.toLowerCase())
						)
			} else {
				this.atendimentos = this.stateSave.atendimentosHome
				this.stateSave = {
					inputActive: false,
					searchs: {
						searchFilterAtendimentos: (arr) => arr,
						searchFilterProspect: (arr) => arr,
					},
				}
			}
		},

		debounceFunction: (fn, timing, wait = 200) => {
			return (...args) => {
				clearTimeout(timing)
				timing = setTimeout(() => fn(...args), wait)
			}
		},

		async initChartRealizados() {
			const request = await api.get(
				`${window.location.origin}/atendimento/grafico_realizados_semana/`
			)

			const data = await request.data

			const dataChart = [
				{
					name: 'Realizado',
					type: 'column',
					data: Object.values(data.grafico_realizado),
				},
				{
					name: 'Previsto',
					type: 'line',
					data: Object.values(data.grafico_previsto),
				},
			]

			window.chartRealizados?.updateOptions({
				series: dataChart,
				labels: Object.keys(data.grafico_previsto),
			})

			this.chartHomeLoading = false

			
		},

		async getTarefas() {
			try {
				const dataInicialFormated = this.data_inicial.split('/').reverse().join('-')
				const dataFinalFormated = this.data_final.split('/').reverse().join('-')
			
				const { data } = await api.get(`/api/tarefas/?data_inicial=${dataInicialFormated}&data_final=${dataFinalFormated}`)
			
				this.tarefas = data.results?.map((i) => ({
					...i,
					nome: this.isFundacao ? i.nome_fundacao : i.nome_cliente_pf,
					politica: this.isFundacao ? i.politica_fundacao : i.politica_cliente,
				}))
				this.tarefas = this.ordenateTarefas()
			} catch (e) {
				errorDefault(e)
			}
		},

		async handlePeriodoTasks() {
			await this.getTarefas()
		},
	async handlePeriodoPolitica(){
			await this.getAtendimentos()
		},
		setDates(){
		
			let hoje = new Date()
			let dataInicial = Quasar.date.subtractFromDate(hoje, {months: 2})
			let dataFinal = Quasar.date.addToDate(hoje, { days: 30 })
			
			this.data_inicial = Quasar.date.formatDate(dataInicial, 'DD/MM/YYYY')
			this.data_final = Quasar.date.formatDate(dataFinal, 'DD/MM/YYYY')
			
			this.data_inicial_politica = Quasar.date.formatDate(dataInicial, 'DD/MM/YYYY')
			this.data_final_politica =  Quasar.date.formatDate(dataFinal,  'DD/MM/YYYY')
		},

		

		ordenateTarefas() {
			return this.tarefas
				.map((i) => {
					if (i.get_status == '3') i.ordem = 1
					if (i.get_status == '1') i.ordem = 2
					if (i.get_status == '2') i.ordem = 3
					if (i.get_status == '0') i.ordem = 4
					return i
				})
				.sort((a, b) => a.ordem - b.ordem)
		},

		async activeAbaTask(progress = true) {
			progress && NProgress.start()
		
			await this.getTarefas()

			// this.ordenateTarefas()

			if (progress) {
				this.abatask = true
			}
			progress && NProgress.done()
		},

		deactivateAbaTask() {
			this.abatask = false
		},

		getPercent(real, total) {
			const calc = (real / total) * 100
			console.log({calc})
			return `${isNaN(calc.toFixed()) ? 0 : calc.toFixed()}%`
		},
		sortedArray({ event, key, dataKey, number }) {
			this.stateSave.searchs.searchFilterAtendimentos = (a) => a
			const target = event.currentTarget
			const att = target.getAttribute('order')

			target
				.closest('thead')
				.querySelectorAll('th')
				.forEach((el) => el !== target && el.removeAttribute('order'))

			if (att == 'up') target.setAttribute('order', 'down')
			if (att == 'down') target.setAttribute('order', 'up')
			if (!att) target.setAttribute('order', 'up')

			const clone = this[dataKey]

			const result = clone.sort((a, b) => {
				const A = number ? a[key] : a[key].toLowerCase()
				const B = number ? b[key] : b[key].toLowerCase()

				if (A < B) return -1
				if (B > A) return 1

				return 0
			})

			this[dataKey] = att == 'up' ? result : result.reverse()
		},

		handleClickSearch(id) {
			this.modalOpen({ id })
		},
	},

	async created() {
		this.setDates()
		!this.isFundacao && this.getProspect()
		this.getAtendimentosQuentes()
		await this.getAtendimentos()
		this.haveProspect = true

		this.activeAbaTask(false)
	},
	
	async mounted() {
		await this.$refs.modalResumo.getTags()
		initTipy()
		this.stateSave.searchs.searchFilterAtendimentos = (newValue, oldValue) => {
			const maped = newValue
				.map((i) => {
					if (i.status == 'Pendente') i.ordem = 1
					if (i.status == 'Atrasado') i.ordem = 2
					if (i.status == 'Realizado') i.ordem = 3
					return i
				})
				.sort((a, b) => a.ordem - b.ordem)

			return maped
		}
	},

	delimiters: ['{*', '*}'], //delimitadores pra n conflitar com o django
}
