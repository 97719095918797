import { useClientes } from '../composables/useClients'
//import modalFiltros from '../../../css/components/modalFiltros.css'
import SelectMultiple from './SelectMultiple'

const { api } = GLOBAL



const {
	formatColumnsToTable,
	onTableFetchServer,
	generateArrayDataForTable,
	calculaSomaTotal,
} = useClientes()

NProgress.configure({ trickleSpeed: 40, trickle: true, minimum: 0.65 })


export default {
	props: ['text', 'limpar', 'submit'],
	
	emits: ['get:big'],

	data() {
		return {
			 MODEL_DEFAULT_VALUE:  {
				ple: '',
				modelo_negocio: '',
				tags: [],
				ativos: [],
				emissores: [],
				d_perfil: '',
				d_perfil_quantitativo: [],
				d_perfil_quantitativo_simples: [],
				perfil_liquidez: '',
				perfil_volatilidade: '',
				vencimento: '',
				fora_fgc: '',
				usuario_responsavel: '',
			},
			filters_url: '',
			dataSelectFilterAtivo: [],
			dataSelectFilterEmissor: [],
			ativos: [],
			emissores: [],
			usuarios: [],
			usuario_responsavel: '',
			model: {
				ple: '',
				modelo_negocio: '',
				tags: [],
				ativos: [],
				emissores: [],
				d_perfil: '',
				d_perfil_quantitativo: [],
				d_perfil_quantitativo_simples: [],
				perfil_liquidez: '',
				perfil_volatilidade: '',
				vencimento: '',
				fora_fgc: '',
				usuario_responsavel: '',
			},
			somaTotal: [],
			options: {
				ple: [],
				modelo_negocio: [
					{ id: 'consultoria', nome: 'consultoria' },
					{ id: 'gestao', nome: 'gestao' },
					{ id: 'distribuicao', nome: 'distribuicao' },
				],
				tags: [],
				d_perfil: [
					{ id: 'True', nome: 'Sim' },
					{ id: 'False', nome: 'Não' },
				],
				d_perfil_quantitativo: [],
				d_perfil_quantitativo_simples: [],
				foraFGC: [],
				perfil_liquidez: [
					{ id: 'False', nome: 'Enquadrado' },
					{ id: 'True', nome: 'Desenquadrado' },
				],
				perfil_volatilidade: [
					{ id: 'False', nome: 'Enquadrado' },
					{ id: 'True', nome: 'Desenquadrado' },
				],
				perfil_rentabilidade: [
					{ id: 'True', nome: 'Enquadrado' },
					{ id: 'False', nome: 'Desenquadrado' },
				]
			},
		}
	},

	components: {
		SelectMultiple,
	},

	methods: {
		async onCleanFilter() {
			this.model ={ ...this.MODEL_DEFAULT_VALUE}
		
			this.ativos = []
			this.emissores = []

			const superUsuario = document.querySelector('#superusuario').value
			const usuarioLogado = document.querySelector('#usuario-logado').value
			this.usuario_responsavel = superUsuario === 'True' ? 'todos' : usuarioLogado

			const form = document.querySelector('#form-create-filtros')
			form.querySelectorAll('input').forEach((i) => {
				i.value = ''
			})
			
			const url = new URL(location)
			url.search = superUsuario === 'True' ? '&usuario_responsavel=todos' : `?usuario_responsavel=${usuarioLogado}`
			history.pushState({}, '', url)

			const by = document.querySelector('.select-paginate-by').value

			await window.tabela.config.plugin.remove('pagination')
			await window.tabela.config.plugin.remove('search')

			this.$emit('get:big', url.search)

			const table = document.querySelector('#table_clientes_patrimonio')

			table.classList.remove('ativos')

			const tableUpdateOptions = {
				columns: formatColumnsToTable(),
				server: {
					url: `${window.origin}/api/tabela_clientepf/principais_clientes/${url.search}`,
					then: (data) => {
						const list = generateArrayDataForTable(data.results)

						return list
					},
					total: (data) => data.count,
				},
				pagination: {
					enabled: true,
					limit: by,
					server: {
						url: (urlApi, page, limit) => {
							return `${urlApi}&page=${++page}&paginate_by=${limit}`
						},
					},
				},
			}

			window.tabela.updateConfig(tableUpdateOptions)?.forceRender()
		},

		async limparFiltros() {
			const btnLimparFiltros = document.querySelector('.limpar-filtros')

			btnLimparFiltros?.addEventListener('click', this.onCleanFilter)
		},

		async getDataFilter() {
			NProgress.start()

			try {
				const { data } = await api.get(
					'api/tabela_clientepf/filtros_principais_clientes/?x='
				)
				NProgress.done()
				return data
			} catch (error) {
				console.error(error)
			}
		},

		async popularSelectsFilter() {

			const urlParams = new URLSearchParams(window.location.search)
			const idsAtivos = urlParams.get('ativos')?.trim().split(',')
			const idsEmissores = urlParams.get('emissores')?.trim().split(',')
			const filtersPerfilQtd = urlParams
				.get('d_perfil_quantitativo')
				?.trim()
				.split(',')
			const filtersPerfilSimplesQtd = urlParams
				.get('d_perfil_simples_quantitativo')
				?.trim()
				.split(',')
			const idsTags = urlParams.get('tag')?.trim().split(',')

			this.ativos = this.dataSelectFilterAtivo.filter((item) => {
				return idsAtivos?.some((i) => i == item.value)
			})
			this.emissores = this.dataSelectFilterEmissor.filter((item) => {
				return idsEmissores?.some((i) => i == item.value)
			})
			this.model.d_perfil_quantitativo =
				this.options.d_perfil_quantitativo.filter((item) => {
					return filtersPerfilQtd?.some((i) => i == item.value)
				})
			this.model.d_perfil_quantitativo =
				this.options.d_perfil_quantitativo_simples.filter((item) => {
					return filtersPerfilSimplesQtd?.some((i) => i == item.value)
				})
			this.usuario_responsavel = urlParams.get('usuario_responsavel') ?? ''
			this.model.ple = urlParams.get('faixa_ple') ?? ''
			this.model.modelo_negocio = urlParams.get('modelo_negocio') ?? ''
			this.model.fora_fgc = urlParams.get('fora_fgc') ?? ''
			this.model.perfil_liquidez = urlParams.get('perfil_liquidez') ?? ''
			this.model.perfil_volatilidade = urlParams.get('perfil_volatilidade') ?? ''
			this.model.d_perfil = urlParams.get('d_perfil') ?? ''
			this.model.d_perfil_simples = urlParams.get('d_perfil_simples') ?? ''
			this.model.tags = this.options.tags.filter((item) => {
				return idsTags?.some((i) => i == item.value)
			})
		},

		async submitDropdown(e) {
			e.preventDefault()
			e.stopPropagation()
			const superUsuario = document.querySelector('#superusuario').value
			const usuarioLogado = document.querySelector('#usuario-logado').value
			this.usuario_responsavel = superUsuario === 'True' ? 'todos' : usuarioLogado
			let search = ''

			search += `&faixa_ple=${this.model.ple ?? ''}`
			search += `&modelo_negocio=${this.model?.modelo_negocio ?? ''}`
			search += `&fora_fgc=${this.model?.fora_fgc ?? ''}`
			search += `&perfil_liquidez=${this.model?.perfil_liquidez ?? ''}`
			search += `&perfil_volatilidade=${this.model?.perfil_volatilidade ?? ''}`
			search += `&d_perfil=${this.model?.d_perfil ?? ''}`
			search += `&d_perfil_simples=${this.model?.d_perfil_simples ?? ''}`
			search += `&d_perfil_quantitativo=${this.model?.d_perfil_quantitativo
				.map((item) => item.value)
				.join(',')}`
			search += `&d_perfil_simples_quantitativo=${this.model?.d_perfil_quantitativo_simples
				.map((item) => item.value)
				.join(',')}`
			search += `&usuario_responsavel=${this.usuario_responsavel}`
			search += `&ativos=${this.ativos.map((ativo) => ativo.value).join(',')}`
			search += `&emissores=${this.emissores
				.map((emissor) => emissor.value)
				.join(',')}`
			search += `&tag=${this.model.tags.map((ativo) => ativo.value).join(',')}`

			const url = new URL(location)
			
			this.filters_url = `?${search}`
			
			url.search = search
			
			history.pushState({}, '', url)
			
			const by = document.querySelector('.select-paginate-by')?.value
			const urlParams = new URLSearchParams(window.location.search)
			const foraFGC = urlParams.get('fora_fgc') == 'Fora do FGC hoje'
			const foraFgcGeral = urlParams.get('fora_fgc') == 'Sim'
			const foraFgcNao = urlParams.get('fora_fgc') == 'Não'
			if (foraFgcGeral || foraFGC) {
				search += `&emissores_fora_fgc_geral=${this.emissores.map((emissor) => emissor.value)
					.join(',')}`
						this.filters_url = `?${search}`
			
			url.search = search
			
			history.pushState({}, '', url)
				}
			const hasEmissor = this.emissores.length > 0
			
			const hasAtivo = this.ativos.length > 0
			const table = document.querySelector('#table_clientes_patrimonio')

			this.$emit('get:big', search)

			await window.tabela?.config.plugin.remove('pagination')
			
			await window.tabela?.config.plugin.remove('search')

			if (hasAtivo) {
				this.somaTotal = await calculaSomaTotal(search)
				table.classList.add('ativos')
			} else {
				table.classList.remove('ativos')
			}

			const tableUpdateOptions = {
				columns: formatColumnsToTable({
					foraFGC,
					foraFgcGeral,
					foraFgcNao,
					emissores: hasEmissor,
					ativos: hasAtivo,
				}),
				server: onTableFetchServer(this.filters_url, {
					foraFGC,
					emissores: hasEmissor,
					ativos: hasAtivo,
					foraFgcGeral,
					foraFgcNao,
					somaTotal: this.somaTotal,
				}),
				pagination: {
					enabled: true,
					limit: by,
					server: {
						url: (urlApi, page, limit) => {
							return `${urlApi}&page=${++page}&paginate_by=${limit}${search}`
						},
					},
				},
			}

			window.tabela?.updateConfig(tableUpdateOptions).forceRender()

			this.closeModalFiltros()
		},

		closeModalFiltros() {
			modal_padrao_vue.closeModal('criar_filtros')
		},

		addFlatPickerInputs() {
			const inputs = document.querySelectorAll('[type=date]:not([type=hidden])')

			inputs.forEach((i) => {
				GLOBAL.initFlatpickr(i, {
					position: 'above',
					mode: 'range',
				})
			})
		},

		setOptionsSelectsFilter(data) {
			this.dataSelectFilterAtivo = data.ativos.map((ativo) => ({
				value: ativo.id,
				label: ativo.nome,
			}))

			this.dataSelectFilterEmissor = data.emissores.map(({ emissor }) => ({
				value: emissor,
				label: emissor,
			}))

			this.usuarios = data.responsaveis

			this.options.ple = data.range_ple

			this.options.fora_fgc = data.ativos_fora_fgc

			this.options.tags = data.tags.map(({ id, titulo }) => ({
				value: id,
				label: titulo,
			}))

			this.options.d_perfil_qualitativo = data.desenquadrado_perfil.map(
				([value, label]) => ({
					id: value,
					nome: label,
				})
			)

			this.options.d_perfil_quantitativo =
				data.desenquadramentos_quantitativos.map((item) => {
					return {
						value: item,
						label: item.includes('(Anual)')
							? item.replace('(Anual)', '(12 meses)')
							: item,
					}
				})
			this.options.d_perfil_quantitativo_simples =
				data.desenquadramentos_quantitativos.map((item) => {
					return {
						value: item,
						label: item.includes('(Anual)')
							? item.replace('(Anual)', '(12 meses)')
							: item,
					}
				})
		},
	},

	async mounted() {
		const data = await this.getDataFilter()

		this.setOptionsSelectsFilter(data)

		this.limparFiltros()

		const urlParams = new URLSearchParams(window.location.search)

		this.filters_url = window.location.search

		this.addFlatPickerInputs()

		
		await this.popularSelectsFilter(data)
		const superUsuario = document.querySelector('#superusuario').value
		const usuarioLogado = document.querySelector('#usuario-logado').value
		this.usuario_responsavel = superUsuario === 'True' ? 'todos' : usuarioLogado
		if (superUsuario === 'True' && !urlParams.get('usuario_responsavel')) {
			this.usuario_responsavel = 'todos'
		} else if (urlParams.get('usuario_responsavel') === 'todos') {
			this.usuario_responsavel = urlParams.get('usuario_responsavel')
		} else {
			this.usuario_responsavel = Number(usuarioLogado)
		}
	

	},


	
	template: html`
		<section
			class="modal--content rounded-md flex-1 !h-max overflow-[initial] modal_filtros "
			data-id="criar_filtros">
			<header
				class="flex items-center justify-between px-24 pt-24 pb-16 bg-primary-pure">
				<h2 class="text-lg font-medium text-white">Filtros </h2>
				<button
					type="button"
					class="w-32 h-32 transition-transform rounded-full cursor-pointer hover:scale-125 bg-white/10 close-btn"
					data-xclose="modal_padrao_vue">
					<svg class="w-32 h-32">
						<use xlink:href="#icon_close"></use>
					</svg>
				</button>
			</header>

			<form
				class="w-full flex-wrap grid grid-cols-2 gap-24  !h-[500px] overflow-y-scroll px-24 pt-24 items-start filter-novo-dashboard-sap radio-container round size-16 "
				id="form-create-filtros">
				<div class="flex flex-col gap-16">
					<div>
						<p class="text-sm text-neutral-100 font-medium">Gerais</p>
						<hr class="text-neutral-80"/>
					</div>
					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Usuário responsável
						</label>

						<q-select
							dense
							id="usuario_responsavel"
							name="usuario_responsavel"
							outlined
							use-input
							map-options
							emit-value
							option-label="nome"
							option-value="id"
							v-model="usuario_responsavel"
							:options="usuarios">
						</q-select>
					</div>

					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium"> PLE </label>

						<q-select
							id="faixa_ple"
							name="faixa_ple"
							dense
							outlined
							use-input
							map-options
							emit-value
							option-label="nome"
							option-value="id"
							v-model="model.ple"
							:options="options.ple">
						</q-select>
					</div>
					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Modelo de negócio
						</label>

						<q-select
							id="modelo_negocio"
							name="modelo_negocio"
							dense
							outlined
							use-input
							map-options
							emit-value
							option-label="nome"
							option-value="id"
							v-model="model.modelo_negocio"
							:options="options.modelo_negocio">
						</q-select>
					</div>
					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Fora do FGC
						</label>

						<q-select
							id="fora_fgc"
							name="fora_fgc"
							dense
							outlined
							use-input
							map-options
							emit-value
							option-label="nome"
							option-value="id"
							v-model="model.fora_fgc"
							:options="options.fora_fgc">
						</q-select>
					</div>

					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Desenquadrado Quantitativo
						</label>
						<select-multiple
							:model="model.d_perfil_quantitativo_simples"
							:options="options.d_perfil_quantitativo_simples"
							@changed="(val) => model.d_perfil_quantitativo_simples = val"
							:submitfc="() => {}">
						</select-multiple>
					</div>

					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Selecione as tags
						</label>
						<select-multiple
							:model="model.tags"
							:options="options.tags"
							@changed="(val) => model.tags = val"
							:submitfc="() => {}">
						</select-multiple>
					</div>

					<input type="hidden" class="invisible" name="tag" value="" />
					<!-- <div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Selecione o intervalo de vencimento
						</label>
						<input
							ref="data"
							type="date"
							name="vencimento"
							placeholder="DD/MM/AAAA - DD/MM/AAAA"
							class="w-full p-10 input-text h-48" />
					</div> -->

					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Selecione os ativos
						</label>
						<select-multiple
							:model="ativos"
							:options="dataSelectFilterAtivo"
							@changed="(val) => ativos = val"
							:submitfc="() => {}">
						</select-multiple>
					</div>

					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Selecione os emissores
						</label>
						<select-multiple
							:model="emissores"
							:options="dataSelectFilterEmissor"
							@changed="(val) => emissores = val"
							:submitfc="() => {}">
						</select-multiple>
					</div>

				</div>
				<div class="flex flex-col gap-16">
					<div>
						<p class="text-sm text-neutral-100 font-medium">Perfil do Investidor</p>
						<hr class="text-neutral-80"/>
					</div>
					<div class="flex flex-col gap-4 w-full">
					<label class="text-sm text-neutral-80 font-medium">
						Concentração
					</label>

					<q-select
						id="d_perfil"
						name="d_perfil"
						dense
						outlined
						use-input
						map-options
						emit-value
						option-label="nome"
						option-value="id"
						v-model="model.d_perfil"
						:options="options.d_perfil_qualitativo">
					</q-select>
				</div>
					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Liquidez
						</label>
						<q-select
							id="perfil_liquidez"
							name="perfil_liquidez"
							dense
							outlined
							use-input
							map-options
							emit-value
							option-label="nome"
							option-value="id"
							v-model="model.perfil_liquidez"
							:options="options.perfil_liquidez">
						</q-select>
					</div>
					<div class="flex flex-col gap-4 w-full">
						<label class="text-sm text-neutral-80 font-medium">
							Volatilidade
						</label>

						<q-select
							id="perfil_volatilidade"
							name="perfil_volatilidade"
							dense
							outlined
							use-input
							map-options
							emit-value
							option-label="nome"
							option-value="id"
							v-model="model.perfil_volatilidade"
							:options="options.perfil_volatilidade">
						</q-select>
					</div>
					<div class="flex flex-col gap-4 w-full">
					<label class="text-sm text-neutral-80 font-medium">
						Rentabilidade
					</label>
					
					<q-select
					id="d_perfil_simples"
					name="d_perfil_simples"
					dense
					outlined
					use-input
					map-options
					emit-value
					option-label="nome"
					option-value="id"
					v-model="model.d_perfil_simples"
					:options="options.perfil_rentabilidade">
				</q-select>
				</div>

				</div>
				
				<div
				class="flex col-span-full bg-white justify-center w-full gap-24 py-24 sticky bottom-0 z-20">
				<button
					type="button"
					class="btn secondary !min-w-160 h-48"
					data-xclose="modal_padrao_vue">
					Cancelar
				</button>

				<button type="button" class="btn !min-w-160   h-48" @click="submitDropdown">
					Consultar
				</button>
			</div>
			</form>
		</section>
	
	`,
	delimiters: ['{*', '*}'],
}


