import { content } from "../../../../../../../tailwind.config";
import atendimentos from "../mixins/atendimentos";
const { showToastify, getChoicesActive } = GLOBAL;

export default {
	props: ["novoAtendimentoOpen","isFundacaoAtendimento", "atendimentoid", "bordaPura", "usuarios", "atendimentos", "edit"],

	data() {
		return {
			
			dados: {
				cliente_pf: null,
				tipo: null,
				data: null,
				data_atendimento:null,
				atendimento: "", 
				notificacao: null,
				fundacao: null,
				politica_atendimento: null,
				titulo_atendimento: "",
				usuario_criacao: null,
				usuario_atualizacao: null,
				user: [],
				usuario_responsavel: null,
				contato_atendimento: "",
			},
			idAtendimento: true,

			qeditor: '',
			requireds: [],
			
		};
	},

	computed: {
		title(){
			return this.atendimentoid ? 'Editar Atendimento' : 'Cadastrar Atendimento'
		},
		dataIsPolitica() {
			return !this.dados.tipo == "0";
		},

		togglePolitica() {
			return {
				disabled: this.dataIsPolitica,
			};
		},
		openClass() {
			return {
				atendimentoOpen: this.novoAtendimentoOpen,
				"borda-pura": this.bordaPura,
			};
		},
	},

	watch: {
		dados() {
	
			this.$emit("atendimentoData", {
				...this.dados,
				atendimento: this.qeditor
			});

		},

		novoAtendimentoOpen() {
			
			if(this.atendimentoid){
				const atendimento = this.atendimentos?.filter(i => i.id === this.atendimentoid)
				
				if (atendimento.length) {
					this.qeditor = atendimento[0].atendimento
					this.dados = { ...atendimento[0] }
					
				}

				
				const choices = GLOBAL.getChoicesActive(
					"tipo-modal-select",
					window.listOfChoices
				);
				choices.setChoiceByValue(atendimento[0]?.tipo);
				GLOBAL.dispatchEventChange("#tipo-modal-select");
				const politica = GLOBAL.getChoicesActive(
					"politica-modal-select",
					window.listOfChoices
				);
				
				// const ultima = politica._currentState.choices.at(-1).value
				this.setSelectPoliticas(this.atendimentos, true)
				
				GLOBAL.dispatchEventChange("#politica-modal-select");
				
				politica.setChoiceByValue(atendimento[0].id).disable()
				GLOBAL.dispatchEventChange("#politica-modal-select");
				const data = document.querySelector("#date-modal-input")
				data.value = atendimento[0].data_atendimento 
				GLOBAL.dispatchEventChange("#date-modal-input");
				const choicesNotificacao = GLOBAL.getChoicesActive(
					"notificacao-modal-select",
					window.listOfChoices
				);
				choicesNotificacao?.setChoiceByValue(atendimento[0].notificacao);
				_BIG("IF", this.atendimentoid);
			}else if (this.novoAtendimentoOpen) {
			
				_BIG("Else");
				
				const choices = GLOBAL.getChoicesActive(
					"tipo-modal-select",
					window.listOfChoices
				);
				
				choices.setChoiceByValue("0");
				GLOBAL.dispatchEventChange("#tipo-modal-select");
				const politica = GLOBAL.getChoicesActive(
					"politica-modal-select",
					window.listOfChoices
				);
				 this.setSelectPoliticas(this.atendimentos, false)
				// const ultima = politica._currentState.choices.at(-1).value
			
				politica.setChoiceByValue(this.atendimentoid).enable();
				GLOBAL.dispatchEventChange("#politica-modal-select");
				GLOBAL.dispatchEventChange("#date-modal-input");
			 this.qeditor = ''
			}
			this.dados.data_atendimento =this.dataModal()
		},
	},
	methods: {
		

		onBeforeEnter(el) {
			GLOBAL.addClassForTime(
				document.querySelector(".tab-informacoes .tabs"),
				"overflow-hidden",
				500
			);
			const pai = el.closest(".cadastrar");
			gsap.set(pai, {
				height: "5.13rem",
			});
		},

		onEnter(el, done) {
			const pai = el.closest(".cadastrar");
			const content = document.querySelector(".modal_full .content");

			gsap
				.timeline({ ease: "power1", delay: 0 })
				.set(pai, {
					clipPath: " clip-path: inset(calc(100% - 5.13rem) 0 0 0)",
				})
				.set(content, {
					overflowY: "hidden",
				})
				.to(pai, {
					clipPath: "inset(0 0 0 0)",
					height: "auto",
					duration: 0.4,
				})
				.add(() => {
					pai.classList.add("withShadow");
				})
				.set(content, {
					overflowY: "auto",
				});

			done();
		},

		onBeforeLeave(el) {
			GLOBAL.addClassForTime(
				document.querySelector(".tab-informacoes .tabs"),
				"overflow-hidden",
				500
			);
		},
		onLeave(el, done) {
			const content = document.querySelector(".modal_full .content");

			const pai = el.closest(".cadastrar");
			gsap
				.timeline({ ease: "power1", delay: 0 })
				.set(content, {
					overflowY: "hidden",
				})
				.to(pai, {
					height: "5.13rem",
					duration: 0.3,
				})
				.add(() => {
					pai.classList.remove("withShadow");
					done();
				}, "-=0.15")
				.set(content, {
					overflowY: "auto",
				});
		},

		toggleAtendimento(open) {
			
			if(!open){
				this.$emit('setId', null)
			}
			this.$emit("open", open);

		},

		setSelectPoliticas(data, editar) {
			
			if(editar){

				this.politicaAtendimentoSelect = [...data]
			}else{
				this.politicaAtendimentoSelect = [...data.filter(i => i.atendimento === null)]
			
			}
		//	this.politicaAtendimentoSelect = [...data]
			const seDateOption = (i) => {
				const data = (v) =>
					`${moment(v.data, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${
						v.atendimento ? ' - Realizado' : ''
					}`
				
					return { value: i.id, label: data(i) }
				}
				
				const opts = this.politicaAtendimentoSelect.map(seDateOption)
			
			this.populateSelect('politica-modal-select', opts)
		},
		populateSelect(selectID, opts) {
			
			const choices = GLOBAL.getChoicesActive(selectID, window.listOfChoices)
			choices?.clearStore()
			choices?.setChoices([...opts], 'value', 'label' , true, true)
		},

		changePolitica(event) {
			const idPolitica = event?.target.value
			
			if (idPolitica) {
				const atendimento = this.atendimentos?.find(i => i.id === Number(idPolitica))
				if (atendimento) {
					this.dados.data = atendimento.data
				}
			}
		},

		// mudaValue({ currentTarget }) {
		// 	const options = currentTarget.querySelectorAll("option")
		// 	const values = Array.from(options).map(i => i.value)
		// 	currentTarget.value = new String([...values])
		// 	// currentTarget.dispatchEvent(new Event("change", { bubbles: true, cancelable: true }));
		// },

		handleChange(event) {
			const form = event.currentTarget;
			const field = event.target;
			this.dados[field.name] =
				field.name === "contato_atendimento"
					? getChoicesActive(field.id, listOfChoices)
							.getValue()
							.map((i) => i.value)
					: field.value;
			
			const requiredField = this.requireds.filter((i) => {
				return (
					(i.ref?.name || i.ref?.$attrs?.name || i.ref?.querySelector(`[name="${field.name}"]`)?.name)  ==
					field.name
				);
			});
			

			if (requiredField?.length) {
				const index = this.requireds.indexOf(requiredField[0]);
				this.requireds[index].data = field.value;
			}
	
			this.$emit("atendimentoData", {...this.dados, 
				atendimento: this.qeditor});
			this.checkRequired(event)();
		},

		getValidacao(value, el) {
			if (!value) {
				el?.classList?.add("required-field");
			} else {
				el?.classList?.remove("required-field");
			}
		},

		addRequiredPoliticas() {
			const politica_atendimentoOBJ = {
				data: this.dados.politica_atendimento,
				ref: this.$refs.politica_atendimento,
			};

			if (this.dados.tipo == "0") {
				if (
					!this.requireds.filter((i) =>
						i.ref?.classList?.contains("politica-atendimento")
					)?.length
				) {
					this.requireds.push(politica_atendimentoOBJ);
				}
			} else if (this.dados.tipo == "1") {
				this.requireds = this.requireds.filter(
					(i) => !i.ref?.classList?.contains("politica-atendimento")
				);
				politica_atendimentoOBJ.ref?.classList.remove("required-field");
			}
		},

		checkRequired(event) {
			function functionCallback(callback) {
				this.addRequiredPoliticas();

				//se é evento pega somente o campo do evento
				if (!callback) {
					if (event && !event.detail) return;
				}

				if (event?.detail) {
					const field = this.requireds.filter(
						(i) =>{
							i.ref == event.target || i.ref == event.target.closest(".field")}
					);

					field?.length && this.getValidacao(event.target.value, field[0].ref);
					return;
				}

				this.requireds.forEach((i) => this.getValidacao(i.data, i.ref));
				// const isEditorValid = this.qeditor ? true : false
				if ( this.requireds.every((i) => i.data)) callback && callback();
				else {
					showToastify("⚠️ Preencha todos os campos Obrigatorios");
				}
			}

			return functionCallback.bind(this);
		},

		emitEnvio() {
			const tituloAtendimentoDefault = 'Atendimento'
			let tituloAtendimento = tituloAtendimentoDefault
			if(this.dados.titulo_atendimento.trim())  tituloAtendimento = this.dados.titulo_atendimento
			GLOBAL.dispatchEventChange("#date-modal-input");

			const enviaEmmit = () => this.$emit("send-atendimento", {		...this.dados, titulo_atendimento:tituloAtendimento,
				atendimento: this.qeditor});
			this.checkRequired()(enviaEmmit);
		},

		dataModal() {
			if(this.atendimentoid){
			
					const atendimento = this.atendimentos?.filter(i => i.id === this.atendimentoid)
					if(atendimento.length){
						return  moment(new Date(atendimento[0].data)).format("DD/MM/YYYY");
					}
			}
			return moment(new Date()).format("DD/MM/YYYY");
		},
		createNovoAtendimento(){
			this.$emit('setId', null)
			this.toggleAtendimento(true)
		}
	},

	mounted() {
	
	
		if(this.atendimentoid){
			const atendimento = this.atendimentos?.filter(i => i.id === this.atendimentoid)
			if(atendimento.length){
				this.qeditor = atendimento[0].atendimento
				this.dados = {...atendimento[0]}
			}

		} 
	
		this.$emit("atendimentoData", {		...this.dados,
		});

		this.requireds = [
			{
				data: this.dados.tipo,
				ref: this.$refs.tipo,
			},
			{
				data: this.dados.cliente_pf,
				ref: this.$refs.cliente_pf,
			},
			{
				data: this.dados.contato_atendimento,
				ref: this.$refs.contato_atendimento,
			},
		
			{
				data: this.dados.data_atendimento,
				ref: this.$refs.data,
			},
			
		];
	 this.dados.data_atendimento = 	this.dataModal()
		const self = this;

		function togglePolitica({ target: { value } }) {
			if (value == "0") {
				self.$refs.politica_atendimento.classList.remove("disabled");
			} else {
				self.$refs.politica_atendimento.classList.add("disabled");
			}
		}

		this.$refs.tipo.addEventListener("change", togglePolitica);
	},

	updated() {
	
	
		this.$emit("atendimentoData",{...this.dados,
			atendimento: this.qeditor});
	},

	template: /* html */ `
		<div class="cadastrar absolute bottom-0 w-full border border-transparent transition-colors rounded-lg" :class="openClass" >
			<div class="card-mini w-full p-16 min-h-[initial] rounded-t-md bg-white z-20"
				>
				<form id="novoAtendimentoForm" @change="handleChange" ref="form">
				<Transition
				@before-enter="onBeforeEnter"
				@enter="onEnter"
				@before-leave="onBeforeLeave"
				@leave="onLeave"
				>

					<div v-show="novoAtendimentoOpen" class="grid grid-cols-6 gap-8 pb-16 cadastrar-content">
						<p class="text-primary-pure font-medium mb-8 col-span-6">{{title}}</p>
						<div class="flex flex-col col-span-3 field" ref="cliente_pf">
							<label class="label text-neutral-80">Cliente*</label>
							<div class="select-container h-48">
								<select js-choices id="cliente-modal-select" name="cliente_pf"
									js-choices-props='{	"searchEnabled": true, "searchChoices": true }'>
									<option value="" disabled selected placeholder="Selecione o cliente"></option>
		
								</select>
							</div>
						</div>
		
						<div class="flex flex-col col-span-3 field" ref="tipo">
							<label class="label text-neutral-80">Tipo*</label>
							<div class="select-container h-48 ">
								<select js-choices  name="tipo" id="tipo-modal-select" ref="tipo">
									<option value="" selected disabled hidden></option>
									<option value="0">Política de atendimento</option>
									<option value="1">Outro</option>
								</select>
							</div>
						</div>
		
						<div class="flex flex-col col-span-6 field" ref="contato_atendimento">
							<label class="label text-neutral-80">Atendido por*</label>
							<div class="select-container h-48 select-multiplo" id="atendido-select-wrapper">
								<select js-choices placeholder="Selecione" multiple id="responsavel-modal-select"  name="contato_atendimento"
									js-choices-props='{ "removeItems": true, "removeItemButton": true}'>
									
								</select>
							</div>
						</div>

						<div class="flex flex-col col-span-2 field" ref="titulo_atendimento">
							<label class="label text-neutral-80" >Título </label>
							<input
							
								class="h-48 input-text"
								placeholder="Digite o titulo"
								js-ativo
								type="text"
								name="titulo_atendimento"
								id="titulo_atendimento"
								ref="titulo_atendimento" />
							
						</div>
		
						<div class="flex flex-col col-span-2 field" ref="data">
							<label class="label text-neutral-80">Data *</label>
							<div class="input-icon icon-left">
								<input class="input-text appearance-none h-48 text-sm"  js-ativo type="text"   name="data_atendimento" id="date-modal-input" v-model="dados.data_atendimento">
								<svg>
									<use xlink:href="#icon_date"></use>
								</svg>
							</div>
						</div>
		
						<div class="politica-atendimento flex flex-col col-span-2 field disabled"  ref="politica_atendimento">
							<label class="label text-neutral-80">Política de Atendimento</label>
							<div class="select-container h-48">
								<select id="politica-modal-select" js-choices name="politica_atendimento" @change="changePolitica">
		
								</select>
								
							</div>
						</div>
		
						<div class="flex flex-col col-span-2" v-if="isFundacaoAtendimento">
							<label class="label text-neutral-80">Notificações</label>
							<div class="select-container h-48">
								<select js-choices  name="notificacao" id="notificacao-modal-select">
									<option value="" selected disabled hidden></option>
									<option value="0" >Risco de reinvestimento</option>
									<option value="1" >Processo de seleção próximo</option>
									<option value="2" >Sem notificações</option>
								</select>
							</div>
						</div>
						<div class="w-full  col-span-6 field ">
							<q-editor
							v-model="qeditor"
							ref="atendimento"
							js-ativo
							name="atendimento"
							:dense="$q.screen.lt.md"
							:toolbar="[
								['unordered', 'left', 'center', 'right', 'justify' ], [ 'bold', 'italic', 'underline', 'strike' ], [ 'undo', 'redo' ] 

							]"
						
						/>
						</div>
						
					</div>

				</Transition>
				</form>
		
				<div v-if="novoAtendimentoOpen" class="flex items-center gap-8">
					<button @click="toggleAtendimento(false)" class="btn secondary h-48 flex-1">Cancelar</button>
					<button class="btn h-48 flex-[2]" @click="emitEnvio">Enviar Atendimento</button>
				</div>
		
				<button v-else class="btn w-full h-48" @click="createNovoAtendimento">Novo
					atendimento</button>
			</div>
		
		</div>
		`,
};
