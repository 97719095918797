export default {
	props: ["agendaPoliticas", "optionsAgendaPoliticas", 'isFundacaoAgenda'],
	data() {
		return {
			isLoadingDelete:false
		}
	},

	mounted() {
		this.initLibs()
	

	},
	updated() {
		this.initLibs()
	
	},

	methods: {
		dataFormated(data) {

			return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")
		},

		initLibs() {
			const selects = [...document.querySelectorAll(".agenda-politicas-select-container select")]
			const choices = selects.map(i => GLOBAL.initChoices(i, {
				searchEnabled: false,
				searchChoices: false,
				itemSelectText: "Selecionar",
				noResultsText: "Nada Encontrado...",

			}))
			selects.forEach(this.handleChange)


			setTimeout(() => {
				const datesInput = [...document.querySelectorAll(".date-input-politicas")]
				window.datesInputPolitica && datesInputPolitica.map(i => i.destroy())
				window.datesInputPolitica = datesInput.map(i => GLOBAL.initFlatpickr(i))
				datesInput.forEach(this.handleChange)

			}, 150);

		},

		eventCallback({ target }) {
			const value = target.value
			const name = target.name
			const id = target.getAttribute("data-id")
			this.sendAlteracaoAgenda(name, id, value)

		},

		handleChange(i) {
			i.addEventListener("change", this.eventCallback)

		},

		async sendAlteracaoAgenda(name, id, value) {

			const isDate = name === "data"
			const dataFormated = isDate ? moment(value, "DD/MM/YYYY").toDate() : ""
			const data = {
				[name]: isDate ? dataFormated : value
			}
			try {
				const request = await axios.patch(`/api/atendimentos/${id}/`, data);
				this.$emit("openModal", this.data)
				GLOBAL.showToastify("✅ Alterado com sucesso.");
			} catch (e) {
				GLOBAL.errorDefault(e, "❌ Ocorreu um erro ao Alterar")
			}
		},
		async deleteAtendimento(id) {
		
			try {
				this.isLoadingDelete = true
			 await axios.delete(`/api/atendimentos/${id}`)
		 
				this.$emit('update-atendimentos')
				GLOBAL.showToastify('✅ Deletado com sucesso')
			
			} catch (e) {
				GLOBAL.errorDefault(e, '❌ Ocorreu um erro ao excluir')
			} finally {
				NProgress.done()
				setTimeout(() => {
					this.isLoadingDelete = false
				}, 500)
			}
		},
	},
	/* :class="{ item.atendimento:'disabled'  }" */
	template: html`
		<li class="flex items-center gap-8" v-for="item in agendaPoliticas" :key="item.id" >

			<!-- <div class="flex-1 font-medium text-sm leading-100 text-neutral-70 p-12 border border-neutral-30 rounded-md h-40" >
				{{dataFormated(item.data)}}
			</div> -->

			<input class="flex-1 text-sm input-text appearance-none  date-input-politicas h-40" js-ativo type="text"  readonly="readonly" name="data" :data-id="item.id"  :value="dataFormated(item.data)">
		
			<div class="select-container w-160 h-40 agenda-politicas-select-container" >
				<select js-choices :data-id="item.id" name="modalidade_cliente_pf_item">

					<option v-for="([key,value]) in optionsAgendaPoliticas" :selected="value == item.modalidade_cliente_pf" :value="key" >{{value }}</option>
				</select>
			</div>
			<button :disabled="isLoadingDelete" @click="() => deleteAtendimento(item.id)" v-if="!isFundacaoAgenda" class="btn tertiary p-8  h-40"   >
				<q-icon size="24px" name="delete" class="cursor-pointer"></q-icon>
			</button>
		</li>
	`
}