export default {
	props: ['type'],

	methods: {
		generateAvatarColor(type) {
			//	console.log('DENTRO AVATAR', GLOBAL.isFundacao())
			if (GLOBAL.isFundacao()) {
				const { saiCliente, sai, ai, na, consultorGestor } =
					GLOBAL.politicas.fundacao
				if (type == saiCliente.id) return '--cor-1:#2945D3'
				else if (type == sai.id) return '--cor-1:#4D82E9'
				else if (type == consultorGestor.id) return '--cor-1:#079F69'
				else if (type == ai.id || type == na.id) return '--cor-1:#6d6f71'
			} else {
				const {
					ultraHigh,
					private: privatee,
					premium,
					na,
				} = GLOBAL.politicas.clientePF
				//console.log(privatee.id, ultraHigh.id, typeof ultraHigh.id, premium.id)
				if (type == ultraHigh.id /* "0" */) return '--cor-1:#840505'
				else if (type == privatee.id /* "1" */) return '--cor-1:#D83232'
				else if (type == premium.id /* "2" */) return '--cor-1:#FF8787'
				else return '--cor-1:#6d6f71'
			}
		},
	},

	template: /* html */ `
		<div class="inline-flex justify-center">
			<svg class="h-40 w-40" :style="generateAvatarColor(type)">
				<use xlink:href="#icon_person"></use>
			</svg>
		</div>
	`,
}
