import ModalResumo from '../components/ModalResumo'
import acessos from '../mixins/acessos'
import AvatarPersona from '../components/AvatarPersona.js'
import outSideClick from '../../modules/outsideCLick'
import ConfirmModal from '../components/ConfirmModal.js'

const { errorDefault, api, FormatREALVirgula, debounceFunction } = GLOBAL

export default {
	mixins: [acessos],
	components: {
		ModalResumo,
		AvatarPersona,
		ConfirmModal
	},
	data() {
		return {
			data: {},
			filter_az: '',
			abasFilter: [],
			dashboard_gerencial: [],
			modalConfirm:{},
			principais_clientes_cliente_pf:
				global_tables.principais_clientes_cliente_pf,
			principais_prospects: global_tables.principais_prospects,
			principais_sap: global_tables.principais_sap,
			acompanhamento_officers: global_tables.acompanhamento_officers,
			grafico_importancia_estado: [],
			es: {
				sigla: '',
			},
			haveModalResumo: true,
			filterMesAtivo: ''
		}
	},

	methods: {
		reloadFilter(fundoID) {
			window.location.href = `/atendimento/dashboard_gerencial_fundacao/?az=${fundoID}`
		},
		openModal({ currentTarget }) {
			const id = currentTarget.getAttribute('data-id')
			const politica = currentTarget.getAttribute('data-politica')
			this.$refs.modalResumo.OpenModalResumo({
				id,
				politica,
				atendimentoID: 0,
			})
		},

		debounce(fn, wait, timing) {
			return (...args) => {
				clearTimeout(timing)
				timing = setTimeout(() => fn(...args), wait)
			}
		},

		submitDropdown() {
			const form = document.querySelector('#form-dropdown')

			form.submit()
		},

		patrimonioFormated(patrimonio) {
			if (patrimonio == '-' || patrimonio?.toLowerCase() == 'none') return '-'
			const value = FormatREALVirgula(patrimonio)
			return value
		},

		arrExample(n) {
			return Array(n).fill(0)
		},

		async getDadosCharts() {
			try {
				const { data: response } = await api.get(
					`/atendimento/graficos_dashboard_gerencial/?az=${this.filter_az}`
				)
				return response
			} catch (err) {
				errorDefault(err)
			}
		},

		fillCharts(data, chart) {
			if (!this.isFundacao) {
				if (chart == chartSegmento) {
					let currentData
					if (this.isFundacao) {
						const { grafico_segmento_fundacao } = data
						currentData = grafico_segmento_fundacao
					} else {
						const { grafico_segmento_cliente_pf } = data
						currentData = grafico_segmento_cliente_pf
					}
					let series = currentData.map((obj) => Number(obj.value))
					chart.updateOptions({
						series,
						labels: currentData.map((obj) => obj.name),
					})
				}

				if (chart == chartProdutos) {
					let currentData
					if (this.isFundacao) {
						const { grafico_principais_produtos_fundacao } = data
						currentData = grafico_principais_produtos_fundacao
					} else {
						const { grafico_principais_produtos_cliente_pf } = data
						currentData = grafico_principais_produtos_cliente_pf
					}

					const series = currentData.map((obj) => Number(obj.value))
					chart.updateOptions({
						series,
						labels: currentData.map((obj) => obj.name),
					})
				}
			} else {
				if (chart == chartParticipacaoFundo) {
					const { grafico_participacao_fundo } = data
					console.log({data, grafico_participacao_fundo})
					let currentData = grafico_participacao_fundo?.sort(
						(a, b) => b.value - a.value
					)

					let series = currentData.map((obj) => Number(obj.value))

					chart.updateOptions({
						series,
						labels: currentData.map((obj) => obj.name),
					})
				}

				if (chart == ple) {
					const { grafico_evolucao_patrimonio } = data
					let currentData = grafico_evolucao_patrimonio
					let series = [
						{
							name: 'Patrimônio',
							data: currentData.map((obj) => Number(obj.value)),
						},
					]
					chart.updateOptions({
						series,
						labels: currentData.map((obj) => obj.name),
					})
				}
				if (chart == chartBar) {
					//const { captacoes } = data

					let currentData = data
					let series = [
						{
							name: 'Ajustado',
							type: 'column',
							data: currentData.map((obj) => Number(obj.captacao_ajustada)),
						},
						{
							name: 'Total',
							type: 'column',
							data: currentData.map((obj) => Number(obj.captacao_total)),
						},

						{
							name: 'Meta',
							type: 'line',
							data: currentData.map((obj) => Number(obj.meta)),
						},
					]

					chart.updateOptions({
						series,
						labels: currentData.map((obj) => obj.semestre),
						chart: {
							width:
								currentData.length <= 12
									? '100%'
									: `${currentData.length * 40}px`,
						},
					})
				}

				if (chart == captacaoMensal) {
					const { fundos_semestre, nomes_fundos } = data

					let arr = Object.entries(fundos_semestre)
					let series = []
					let label = []
					for ([chave, itemFundoSemestre] of arr) {
						label.push(chave)
					}

					nomes_fundos.forEach((array) => {
						let valores = []
						for ([chave, itemFundoSemestre] of arr) {
							let valor = 0
							if (itemFundoSemestre.length == 1) {
								if (itemFundoSemestre[0].nome === array[0]) {
									valor = itemFundoSemestre[0].valor
								} else {
									valor = 0
								}
							} else {
								itemFundoSemestre.forEach((item, index) => {
									if (index == 0) {
										if (item.nome === array[0]) {
											valor = item.valor
										}
									} else {
										if (item[0].nome === array[0]) {
											valor = item[0].valor
										}
									}
								})
							}
							valores.push(valor)
						}
						series = [
							...series,
							{
								name: array[0],
								data: valores,
							},
						]
						valores = []
					})

					chart.updateOptions({
						series,

						xaxis: {
							type: 'category',
							categories: label,
						},
					})
				}
			}
		},

		alternarValor() {
			const btn = document.querySelector('.alternar-valor')
			const btnAjustado = document.querySelector('.alternar-valor-ajustado')

			btnAjustado.addEventListener('click', async (eventClick) => {
				this.toggleButtonClasses(eventClick, btn)
			
				let data = await this.getDadosCharts()
				const { fundos_semestre, nomes_fundos } = data

				let arr = Object.entries(fundos_semestre)
				let series = []
				let label = []
				for ([chave, itemFundoSemestre] of arr) {
					label.push(chave)
				}

				nomes_fundos.forEach((array) => {
					let valores = []
					for ([chave, itemFundoSemestre] of arr) {
						let valor = 0
						if (itemFundoSemestre.length == 1) {
							if (itemFundoSemestre[0].nome === array[0]) {
								valor = itemFundoSemestre[0].valor_ajustado
							} else {
								valor = 0
							}
						} else {
							itemFundoSemestre.forEach((item, index) => {
								if (index == 0) {
									if (item.nome === array[0]) {
										valor = item.valor_ajustado
									}
								} else {
									if (item[0].nome === array[0]) {
										valor = item[0].valor_ajustado
									}
								}
							})
						}
						valores.push(valor)
					}
					series = [
						...series,
						{
							name: array[0],

							data: valores,
						},
					]
					valores = []
				})
				captacaoMensal.updateOptions({
					series,

					xaxis: {
						type: 'category',
						categories: label,
					},
				})
			})

			btn.addEventListener('click', async (eventClick) => {
				this.toggleButtonClasses(eventClick, btnAjustado)
				let data = await this.getDadosCharts()

				this.fillCharts(data, captacaoMensal)
			})
		},

		filtrar() {
			const acima = document.querySelector('input[name="valor_acima"]')
			const abaixo = document.querySelector('input[name="valor_abaixo"]')

			const selectEstado = document.querySelector('select[name="estado"]')
			const choiceEstado = GLOBAL.getChoicesActive(
				selectEstado.id,
				listOfChoices
			)
			const valuesEstadosSelecionados = choiceEstado.getValue(true)

			const selectFundo = document.querySelector('select[name="fundo"]')
			const choiceFundo = GLOBAL.getChoicesActive(selectFundo.id, listOfChoices)
			const valuesFundosSelecionadas = choiceFundo.getValue(true)

			let filterEstado = []
			valuesEstadosSelecionados.forEach((item) =>
				filterEstado.push(`estado,${item}`)
			)
			let filterFundo = []
			valuesFundosSelecionadas.forEach((item) =>
				filterFundo.push(`fundo,${item}`)
			)
			let filter = ''
			filterEstado.forEach((i) => {
				filter += handleCallback(i)
			})
			filterFundo.forEach((i) => {
				filter += handleCallback(i)
			})
			filter += handleCallback(
				`valor_acima,${Number(GLOBAL.FormatREALBackEnd(acima.value)) || ''}`
			)

			filter += handleCallback(
				`valor_abaixo,${Number(GLOBAL.FormatREALBackEnd(abaixo.value)) || ''}`
			)

			function handleCallback(itemFilter) {
				const filtro = itemFilter?.split(',')
				const [param, value] = filtro

				let filtersOBJ = {}
				filtersOBJ[param] = value

				const filtros = Object.entries(filtersOBJ).map(
					([key, value]) => `&${key.trim()}=${value.trim()}`
				)

				const newFilters = `${filtros.join('')}`

				return newFilters
			}
			const url = new URL(window.location)
			window.location.href = `${url.origin}${url.pathname}?${filter}`
		},

		sortedArray(event, key, dataKey, number) {
			// this.stateSave.searchs.searchFilterAtendimentos = (a) => a
			const target = event.currentTarget
			const att = target.getAttribute('order')

			target
				.closest('thead')
				.querySelectorAll('th')
				.forEach((el) => el !== target && el.removeAttribute('order'))

			if (att == 'up') target.setAttribute('order', 'down')
			if (att == 'down') target.setAttribute('order', 'up')
			if (!att) target.setAttribute('order', 'up')

			const clone = this[dataKey]

			const result = clone.sort((a, b) => {
				const A = number
					? a[key].replaceAll('.', '').replaceAll(',', '')
					: a[key].toLowerCase()
				const B = number
					? b[key].replaceAll('.', '').replaceAll(',', '')
					: b[key].toLowerCase()

				if (number) {
					return A - B
				} else {
					if (A < B) return -1
					if (B > A) return 1

					return 0
				}
			})

			this[dataKey] = att == 'up' ? result : result.reverse()
		},

		// getAtendimentos() {
		// 	console.log("Hello darknees my old friend.");
		// },

		// getAtendimentosQuentes() {
		// 	console.log("Hello darknees my old friend.");

		// },

		// openModal({ currentTarget }) {
		// 	const id = currentTarget.getAttribute("data-id")
		// 	const politica = currentTarget.getAttribute("data-politica")
		// 	this.$refs.modalResumo.OpenModalResumo({ id, politica, atendimentoID: 0 });

		// }
		openModalProcesso() {
			modal_padrao.openModal('modal_filtro')
		},

		openModalDeleteProcesso(id) {
				
				this.modalConfirm = {
					open: true,
					text: `Deseja mesmo excluir esse processo? `,
					data: {id},
					id: 'delete_processo',
					botao: 'Excluir',
					header: 'Excluir Processo',
				}
		},

		hiddenSectionModal() {
			const modal = document.querySelector('.modal_padrao')
			const btns = document.querySelectorAll('.btn-modal')

			btns.forEach((i) => {
				i.addEventListener('click', () => {
					const id = i.dataset.id
					modal.querySelectorAll(`section`).forEach((i) => {
						i.classList.add('hidden')
					})
					modal
						.querySelector(`section[data-id=${id}]`)
						.classList.remove('hidden')
				})
			})
		},

		fillMap() {
			let colors = [
				'#D2E2F3',
				'#93C7FF',
				'#83BFFF',
				'#75B5F0',
				'#74B7FF',
				'#71B5FF',
				'#6BA4D4',
				'#639DD4',
				'#59A9FF',
				'#55A7FF',
				'#52A8FF',
				'#4FA7FF',
				'#46A3FF',
				'#459EF6',
				'#2D90ED',
				'#2A94FF',
				'#2793FF',
				'#0080FF',
				'#699CC9',
				'#528FC4',
				'#3A7DB8',
				'#437199',
				'#437199',
				'#186EBF',
				'#195386',
				'#164A78',
				'#164269',
				'#163C5E',
				'#153654',
				'#153047',
			].reverse()

			const estadosOrdenados = this.grafico_importancia_estado
				.sort(function (x, y) {
					return x.value - y.value
				})
				.reverse()

			estadosOrdenados.forEach((regiao, i) => {
				const path = document.querySelector(
					`.svg-es path[data-es="${regiao.name}"]`
				)
				if (path) {
					if (regiao.value > 0) {
						path.style.fill = colors[i]
					} else {
						path.style.fill = '#D2E2F3CC'
					}
				}
			})
		},

		async hoverES(e, es) {
			const tooltip = document.querySelector('.tooltip-es')
			tooltip.style.opacity = 0
			tooltip.style.transform = 'translateY(16px)'

			const path = document.querySelector(`.svg-es path[data-es="${es}"]`)


			this.es.sigla = es

			const data = this.grafico_importancia_estado.find(
				(obj) => obj.name.toLowerCase().trim() == es.toLowerCase()
			)

			if (data) {
				this.es = {
					sigla: data.name + ' - ',
					fullName: data.nome,
					value: 'R$ ' + this.patrimonioFormated(data.value),
				}
			} else {
				this.es = {
					sigla: '',
					fullName: 'Sem dados',
					value: '',
				}
			}

			tooltip.style.opacity = 1
			tooltip.style.transform = 'translateY(0)'
		},

		hoverLeave(e) {
			this.es = {
				sigla: '',
			}
			const tooltip = document.querySelector('.tooltip-es')
			tooltip.style.opacity = 0
			tooltip.style.transform = 'translateY(16px)'
		},

		addBuscaSelect(listOfChoices) {
			const fundacao = document.querySelector('[name=fundacao]')

			if (!fundacao) return
			const hasAttr = fundacao.getAttribute('js-choices-props')
			const props = hasAttr ? JSON.parse(hasAttr) : {}

			let newFundacao = GLOBAL.initChoices(fundacao, {
				searchEnabled: true,
				searchChoices: true,
				itemSelectText: 'Selecionar',
				noResultsText: 'Nada Encontrado...',
				noResultsText: '...',
				noChoicesText: '...',
				...props,
			})

			window.listOfChoices = [...listOfChoices, newFundacao]
		},

		toggleDisablebBtnNovoProcesso(fundacaoValue) {
			const btn = document.querySelector('.submit-processo')
			if (fundacaoValue) {
					btn.classList.remove('disabled')
			} else {
					btn.classList.add('disabled')
			}
		 },
		

		eventProcesso() {
			console.log('Passou aquiiii 😁😁')
			const fundacao = document.querySelector('[name=fundacao]')
			const btn = document.querySelector('.submit-processo')
			fundacao.addEventListener('change', () => {
				this.toggleDisablebBtnNovoProcesso(fundacao.value)
			})
		 this.toggleDisablebBtnNovoProcesso(fundacao.value)

			btn.addEventListener('click', () => {
				this.cadastrarProcessoSeletivo()
			})
		},

		limpaDados(dados) {
			let arrDados = Object.entries(dados)
			for ([key, item] of arrDados) {
				if (key == 'fundacao') {
					GLOBAL.setValueInChoices(item.id, '')
				}
				item.value = ''
			}
		//	this.eventProcesso()
		},

		async cadastrarProcessoSeletivo() {
			
			let elementos = {
				fundacao: document.querySelector('[name=fundacao]'),
				estrategia: document.querySelector('[name=estrategia]'),
				valor: document.querySelector('[name=valor]'),
				data: document.querySelector('[name=deadline]'),
				observacoes: document.querySelector('[name=observacoes]'),
			}
			let data = {
				fundacao: elementos.fundacao.value || null,
				estrategia: elementos.estrategia.value,
				valor: elementos.valor.value
					? GLOBAL.FormatREALBackEnd(elementos.valor.value)
					: null,
				data: elementos.data.value
					? elementos.data.value.split('/').reverse().join('-')
					: null,
				observacoes: elementos.observacoes.value || null,
			}

			try {
				const { data: response } = await axios.post(
					`/api/processo_seletivo/`,
					data
				)
				await this.populaProcessos()
				GLOBAL.showToastify('Processo cadastrado')
				this.limpaDados(elementos)

				return response
			} catch (err) {
				console.log(err)
				GLOBAL.showToastify('Processo não cadastrado')
			}
		},

		geraTemplateProcesso(i) {
			return html`<tr class="" data-tippy-content="${i.observacoes ?? '-'}">
								<td class="py-10" >
								${i.nome_fundacao}
			
								</td >
								<td  class="py-10">
									${i.estrategia || '-'}
							
								</td>
							
								<td class="py-10">
									${i.data?.split('-').reverse().join('/') || '-'}
								</td >
								<td class="py-10">
									<div class="flex items-center">
									R$ ${GLOBAL.FormatREAL(i.valor) || GLOBAL.FormatREAL('0.00')}
									</div>
								</td >
								<td class="py-10">
									<div class="flex items-center justify-center">
										<button type="button" id_processo="${i.id}"
										class="delete_processo w-full relative transition-transform place-items-center   cursor-pointer  text-primary-pure"
										>
											<svg class="w-20 h-20 ">
												<use xlink:href="#icon_delete"></use>
											</svg>						
									</button>
									</div>
								</td >
						</tr>`
		},

		addClickDeleteProcesso() {
			document.querySelectorAll('.delete_processo').forEach(button => {
				button.addEventListener('click', () => {
					const id = button.getAttribute('id_processo')
					
					this.openModalDeleteProcesso(id)
				});
    
  });
		},

		async deleteProcessoSeletivo() {
			try {
				const id = this.modalConfirm.data?.id
	
				const responseDelete = await axios.delete(`/api/processo_seletivo/${id}`)
				
				if (responseDelete.data?.error) {
					throw new Error(responseDelete.data?.error)
				}
				GLOBAL.showToastify('✅ Processo excluido com sucesso')
				this.modalConfirm = {}
				await this.populaProcessos()
			} catch (e) {
					GLOBAL.showToastify( `❌ ${e.message || 'Erro ao excluir processo'}`)
			} 
		},

		async populaProcessos() {
			try {
				const { data: response } = await axios.get(`/api/processo_seletivo/`)
				const body = document.querySelector('.body-processos-seletivos')
				body.innerHTML = ''
				response?.results.forEach((i) => {
					body.innerHTML += this.geraTemplateProcesso(i)
					
				})
				this.addClickDeleteProcesso()
				tippy('[data-tippy-content]', {
					allowHTML: true,
					placement: 'top-start',
					boundary: 'HTMLElement',
				})
				return response
			} catch (err) {
				console.log(err)
			}
		},

		
		
		async filterValuesChart(qtdMeses) { 
			const {captacoes, captacoes_12_meses,captacoes_24_meses, captacoes_36_meses} = await this.getDadosCharts()
			if (qtdMeses === this.filterMesAtivo) {
				this.filterMesAtivo  = ''
			} else {
				this.filterMesAtivo  = qtdMeses
			}
			switch (this.filterMesAtivo) {
				case 12: 
					this.fillCharts(captacoes_12_meses, chartBar)
					return
				case 24: 
					this.fillCharts(captacoes_24_meses, chartBar)
					return
				case 36: 
					this.fillCharts(captacoes_36_meses, chartBar)
					return
				default: 
					this.fillCharts(captacoes, chartBar)
					return
			}
		},
		

		toggleButtonClasses(eventClick, buttonNoClicked) {
			eventClick.target.classList.remove('quartenary')
			eventClick.target.classList.add('secondary')

			buttonNoClicked.classList.add('quartenary')
			buttonNoClicked.classList.remove('secondary')
		},

		setParticipationChartOptions(responseDadosCharts) {
			const { grafico_participacao_fundo2 } = responseDadosCharts

			const labels = grafico_participacao_fundo2.map(
				(item_grafico) => item_grafico.name
			)
			const series = grafico_participacao_fundo2.map((item_grafico) =>
				Number(item_grafico.value)
			)

			chartParticipacaoFundo.updateOptions({
				series,
				labels,
			})
		},
		async handleClickButtonAjustado(eventClick, buttonValueNominal) {
			this.toggleButtonClasses(eventClick, buttonValueNominal)
			const responseDadosCharts = await this.getDadosCharts()
			
			this.setParticipationChartOptions(responseDadosCharts)
		},

		async handleClickbuttonNominal(eventClick, buttonValueAjustado) {
			this.toggleButtonClasses(eventClick, buttonValueAjustado)
			const responseDadosCharts = await this.getDadosCharts()

			this.fillCharts(responseDadosCharts, chartParticipacaoFundo)
		},

		initializeChartToggle() {
			const buttonValueNominal = document.querySelector('.chart-part')
			const buttonValueAjustado = document.querySelector('.chart-part-ajustado')

			buttonValueAjustado.addEventListener('click', async (eventClick) =>
				this.handleClickButtonAjustado(eventClick, buttonValueNominal)
			)

			buttonValueNominal.addEventListener('click', async (eventClick) =>
				this.handleClickbuttonNominal(eventClick, buttonValueAjustado)
			)
		},

		async setFilterAz() {
			const { abas } = await this.getDadosCharts()
			const abaGeral = abas?.find(aba => String(aba.nome)?.toLowerCase() === 'geral')
			if(abaGeral)
				this.reloadFilter(abaGeral.id)
			
		}
	},


	async mounted() {
		GLOBAL.initFlatpickr(document.querySelector('[name=deadline'), {
			position: 'above',
		})
		const urlParams = new URLSearchParams(window.location.search);
		const az = urlParams.get("az");
		if (!az) {
			
			await this.setFilterAz(az)
		} 
		this.filter_az = az ?? ''
		this.alternarValor()
		this.initializeChartToggle()
		let data = await this.getDadosCharts()
		let { grafico_importancia_estado, abas } = data
		this.grafico_importancia_estado = grafico_importancia_estado
		
		this.abasFilter = abas 
		
		setTimeout(() => {
			if (!this.isFundacao) {
			chartSegmento && this.fillCharts(data, chartSegmento)
			chartProdutos && this.fillCharts(data, chartProdutos)
		} else {
			console.log({chartParticipacaoFundo})
			chartParticipacaoFundo && this.fillCharts(data, chartParticipacaoFundo)
			ple && this.fillCharts(data, ple)
			chartBar && this.fillCharts(data.captacoes, chartBar)
			captacaoMensal && this.fillCharts(data, captacaoMensal)
			}
			this.fillMap()

		document.querySelector('input[js-table-search]').value =
			urlParams.get('filter')
		document.querySelector('select[name="usuario_responsavel"]').value =
			urlParams.get('usuario_responsavel')

		this.addBuscaSelect(listOfChoices)
		this.eventProcesso()
		this.populaProcessos()
		}, 300)
		
		

		
		
	},

	beforeMount() {
		this.$nextTick(
			function () {
				console.log(this.$refs)
			}.bind(this)
		)
	},


	delimiters: ['{*', '*}'], //delimitadores pra n conflitar com o django
}
