import { html as htmlGrid, Grid } from 'gridjs'
import { capitalize } from 'lodash'

const { generateAvatarColor, formatReal2, api, convertDecimalInHexadecimal } =
	GLOBAL

export function useClientes() {
	let emissorFGCChart

	function formatEmissorCell(cell, isEmissorName, clientId) {
		return cell
			? htmlGrid(html`
					<ul class="flex flex-col gap-8">
						${cell
							.map(
								(i) =>
									`<li style="height: 22px;
									overflow: hidden;" title="${i}"><button class="text-left hover:bg-primary" ${
										isEmissorName
											? `data-emissor="${i}" data-client="${clientId}"`
											: ''
									}>${i}</button></li>`
							)
							.join('')}
					</ul>
			  `)
			: htmlGrid(html``)
	}

	function formatOfficerCell(cell) {
		console.log(cell)
		return cell
			? htmlGrid(html`
					<ul class="flex flex-col gap-8">
						${cell
							.map(
								(i) =>
								(
									`<li style="height: 22px;
									overflow: hidden;">
									${i
										.split('.')
										.map((word) => capitalize(word))
										.join(' ')}
									</li>`
								)
							)
							.join(' ')}
					</ul>
			  `)
			: htmlGrid(html``)
	}

	function formatColumnsToTable(
		{ foraFGC, foraFgcGeral, foraFgcNao, emissores, ativos } = {
			foraFGC: false,
			foraFgcGeral: false,
			foraFgcNao: false,
			emissores: false,
			ativos: false,
		}
	) {
		const columns = [
			{
				name: 'id',
				hidden: true,
			},
			{ name: 'politica', hidden: true },
			{
				name: 'Nomes',
				width: '320px',
				formatter: (cell, row) => {
					if (cell === 'Totalizador')
						return htmlGrid(html`<div class="flex gap-4 items-center">
							${cell}
						</div>`)
					else {
						return cell
							? htmlGrid(html`<div class="flex gap-4 items-center">
									<div class="cursor-pointer">
										<div class="inline-flex justify-center">
											<svg
												class="h-40 w-40"
												style="${generateAvatarColor(row.cells[1].data)}">
												<use xlink:href="#icon_person"></use>
											</svg>
										</div>
									</div>
									${cell}
							  </div>`)
							: htmlGrid(html`<div class="flex gap-4 items-center"></div>`)
					}
				},
			},
			{
				name: 'Patrimônio',
				width: '150px',
				formatter: (cell) => {
					return cell ? `${formatReal2(cell)}` : ''
				},
			},
		
			{
				name: 'PLE',
				width: '150px',
				formatter: (cell) => {
					
					return cell ? `${formatReal2(cell)}` : ''
				},
			},
				{
				name: 'Officers',
				width: '150px',
					formatter: (cell) => {
						
					return cell ? formatOfficerCell(cell) : ''
				},
			},
		]

		if (foraFGC) {
			columns.push(
				{
					name: 'Emissores fora do FGC hoje',
					width: '200px',
					formatter: (cell, row) =>
						formatEmissorCell(cell, true, row.cells[0].data),
				},
				{
					name: 'Valor Excedente do FGC hoje',
					width: '200px',
					formatter: (cell) => formatEmissorCell(cell),
				}
			)
		} else if (foraFgcGeral) {
			columns.push(
				{
					name: 'Emissores fora do FGC',
					width: '200px',
					formatter: (cell, row) =>
						formatEmissorCell(cell, true, row.cells[0].data),
				},
				{
					name: 'Valor Excedente do FGC',
					width: '200px',
					formatter: (cell) => formatEmissorCell(cell),
				}
			)
		} else if (!foraFgcNao) {
			if (ativos) {
				columns.push(
					{
						name: 'Ativos',
						width: '200px',
						formatter: (cell) => {
							return cell ? cell.join(', ') : ''
						},
					},
					{
						name: 'Patrimônio por ativo',
						width: '200px',
						formatter: (cell) => {
							let list = cell && cell?.map((i) => {
								return formatReal2(i)
							})

							return list ? list.join(', ') : ''
						},
					},
					{
						name: 'Pr. carteira',
						width: '150px',

						formatter: (cell) => {
							return cell ? cell.join(', ') : ''
						},
					},
					{
						name: 'Pr. ativo por categoria',
						width: '220px',
						formatter: (cell) => {
							return cell ? cell.join(', ') : ''
						},
					}
				)
			}
			if (emissores) {
				columns.push(
					{
						name: 'Emissores',
						width: '200px',
						formatter: (cell) => {
							return cell ? cell.join(', ') : ''
						},
					},
					{
						name: 'Patrimônio por emissor',
						width: '250px',
						formatter: (cell) => {
							let list = cell &&  cell?.map((i) => {
								return formatReal2(i)
							})

							return list ? list.join(', ') : ''
						},
					},
					{
						name: htmlGrid('<div style="white-space: normal;">Percentual emissor RF (%)</div>'),
						width: '180px',
						formatter: (cell) => {
							return cell ? cell.join(', ') : ''
						},
					},
					{
						name: htmlGrid('<div style="white-space: normal;">Percentual emissor Global (%)</div>'),
						width: '180px',
						formatter: (cell) => {
							return cell ? cell.join(', ') : ''
						},
					}
				)
			}
		}

		return columns
	}

	function onTableFetchServer(
		search,
		{ foraFGC, ativos, emissores, foraFgcGeral, foraFgcNao, somaTotal }
	) {
		return {
			url: `${window.origin}/api/tabela_clientepf/principais_clientes/${search}`,
			then: (data) => {
				let list = []

				if (foraFGC) {
					list = data?.results?.map((item) => ({
						...item,
						emissores_fora_fgc_hj: item?.emissores_fora_fgc_hj
							? item.emissores_fora_fgc_hj.map((i) => i[0])
							: [],
						emissores_fora_fgc_hj_valor: item.emissores_fora_fgc_hj
							? item.emissores_fora_fgc_hj.map((i) => formatReal2(i[1]))
							: [],
					}))
				} else if (foraFgcGeral) {
					list = data?.results?.map((item) => ({
						...item,
						emissores_fora_fgc_geral: item?.emissores_fora_fgc_geral
							? item.emissores_fora_fgc_geral.map((i) => i[0])
							: [],
						emissores_fora_fgc_geral_valor: item?.emissores_fora_fgc_geral
							? item.emissores_fora_fgc_geral.map((i) => formatReal2(i[1]))
							: [],
					}))
				} else {
					list = data?.results
				}

				setTimeout(() => {
					const emissoresBtns = Array.from(
						document.querySelectorAll('[data-emissor]')
					)

					emissoresBtns.forEach((btn) => {
						btn.addEventListener('click', (event) =>
							openEmissorFGCAtivosModal(
								event,
								btn.dataset.emissor,
								Number(btn.dataset.client)
							)
						)
					})
				}, 1000)
				console.log({list})
				return generateArrayDataForTable(
					list,
					ativos,
					emissores,
					foraFGC,
					foraFgcGeral,
					foraFgcNao,
					somaTotal
				)
			},
			total: (data) => data.count,
		}
	}

	async function openEmissorFGCAtivosModal(event, emissorName, clientId) {
		event.stopPropagation()

		if (emissorFGCChart && emissorFGCChart instanceof ApexCharts) {
			emissorFGCChart.destroy()
		}

		modal_padrao_vue.openModal('emissor_fgc_ativos')

		const response = await api.get(
			`/api/tabela_clientepf/detalhes_emissor_fora_fgc/?cliente=${clientId}&emissor=${emissorName}`
		)

		const { emissor, faixa_fgc, ativos } = response.data

		let ativosFormatted = {}

		ativos.forEach((ativo) =>
			ativo.detalhes.forEach((ativoDetalhe) => {
				if (!ativosFormatted[ativoDetalhe.ativo]) {
					ativosFormatted[ativoDetalhe.ativo] = []
				}

				ativosFormatted[ativoDetalhe.ativo].push(
					ativoDetalhe.valor_rentabilizado
				)
			})
		)

		emissorFGCChart = new ApexCharts(
			document.getElementById('chart-emissor-fgc-ativos'),
			{
				title: {
					text: emissor,
					align: 'center',
					style: {
						marginBottom: '16px',
						color: '#000',
					},
				},
				chart: {
					type: 'bar',
					stacked: true,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				series: Object.entries(ativosFormatted).map(([key, value]) => ({
					name: key,
					data: value,
				})),
				xaxis: {
					type: 'category',
					categories: ativos.map((ativo) => ativo.vencimento),
				},
				yaxis: {
					labels: {
						formatter: (value) => {
							return formatReal2(value)
						},
					},
				},
				dataLabels: {
					enabled: false,
					background: {
						borderWidth: 0,
					},
				},
				colors: Object.keys(ativosFormatted).map(
					(_, index) =>
						`#0E4A65${convertDecimalInHexadecimal(255 - index * 15)}`
				),
				annotations: {
					yaxis: [
						{
							y: faixa_fgc,
							strokeDashArray: 10,
							borderColor: '#222222',
							borderWidth: 2,
							borderRadius: 4,
							fillColor: '#222',
							label: {
								text: 'Faixa FGC',
							},
						},
					],
				},
				legend: {
					show: true,
					showForSingleSeries: true,
					showForNullSeries: true,
					showForZeroSeries: true,
					fontSize: '10px',
					fontFamily: 'Roboto Flex, sans-serif',
					fontWeight: 400,
				},
			}
		)

		emissorFGCChart.render()
	}

	function generateArrayDataForTable(
		list,
		ativos,
		emissores,
		fora_fgc,
		fora_fgc_geral,
		fora_fgc_nao,
		soma_total
	) {
		const array = list.map(
			({
				id,
				politica,
				nome,
				patrimonio_interno,
				patrimonio_externo,
				usuario_responsavel,
				emissores_fora_fgc_hj,
				emissores_fora_fgc_hj_valor,
				emissores_fora_fgc_geral,
				emissores_fora_fgc_geral_valor,
				ativo_nome,
				ativo_emissor_porcentagem,
				ativo_emissor_valor,
				ativo_emissor_porcentagem_rf,
				ativo_emissor,
				ativo_nome_valor,
				ativo_nome_porcentagem,
				ativo_nome_porcentagem_rf,
			}) => {
				if (fora_fgc_nao) {
					return [id, politica, nome, patrimonio_interno, patrimonio_externo,usuario_responsavel]
				} else if (fora_fgc) {
					return [
						id,
						politica,
						nome,
						patrimonio_interno,
						patrimonio_externo,
						usuario_responsavel,
						emissores_fora_fgc_hj,
						emissores_fora_fgc_hj_valor,
					]
				} else if (fora_fgc_geral) {
					return [
						id,
						politica,
						nome,
						patrimonio_interno,
						patrimonio_externo,
						usuario_responsavel,
						emissores_fora_fgc_geral,
						emissores_fora_fgc_geral_valor,
					]
				} else if (ativos && emissores) {
					return [
						id,
						politica,
						nome,
						patrimonio_interno,
						patrimonio_externo,
						usuario_responsavel,
						ativo_nome,
						ativo_emissor_porcentagem,
						ativo_emissor_porcentagem_rf,
						ativo_emissor_valor,
						ativo_emissor,
						ativo_nome_valor,
						ativo_nome_porcentagem,
						ativo_nome_porcentagem_rf,
					]
				} else if (ativos) {
					return [
						id,
						politica,
						nome,
						patrimonio_interno,
						patrimonio_externo,
						usuario_responsavel,
						ativo_nome,
						ativo_nome_valor,
						ativo_nome_porcentagem,
						ativo_nome_porcentagem_rf,
					]
				} else if (emissores) {
					return [
						id,
						politica,
						nome,
						patrimonio_interno,
						patrimonio_externo,
						usuario_responsavel,
						ativo_emissor,
						ativo_emissor_valor,
						ativo_emissor_porcentagem,
						ativo_emissor_porcentagem_rf,
					]
				} else {
					return [id, politica, nome, patrimonio_interno, patrimonio_externo, usuario_responsavel]
				}
			}
		)
		if (ativos && soma_total.length >= 0) {
			array.push(['', '', 'Totalizador', '', '', '', '', soma_total, '', ''])
		}
		return array
	}

	async function getDadosTablePatrimonioCliente(params) {
		try {
			
			const request = await api.get(
				`api/tabela_clientepf/principais_clientes/${params}`
			)

			return request.data?.results
		} catch (error) {
			console.error(error)
		}
	}

	function createPatrimonioClienteTable(
		search,
		{ foraFGC, foraFgcGeral, foraFgcNao, emissores, ativos, somaTotal, by }
	) {
		return new Grid({
			resizable: true,
			columns: formatColumnsToTable({
				foraFGC,
				foraFgcGeral,
				foraFgcNao,
				emissores,
				ativos,
			}),
			autoWidth: true,
			width: '100%',
			sort: false,
			server: onTableFetchServer(search, {
				foraFGC,
				foraFgcNao,
				foraFgcGeral,
				ativos,
				emissores,
				somaTotal,
			}),
			search: {
				debounceTimeout:500,
				server: {
					url: (prev, keyword) => {
						return `${prev}&search=${keyword}&paginate_by=9999`
					},
				},
			},
			pagination: {
				enabled: true,
				limit: by ?? 10,
				server: {
					url: (urlApi, page, limit) => {
						return `${urlApi}&page=${++page}&paginate_by=${limit}`
					},
				},
			},
			style: {
				container: {
					border: 'none',
				},
				table: {
					border: 'none',
				},
				tr: {
					border: 'none',
				},

				th: {
					'border-bottom': '1px solid #e9ecef',
					// 'flex-wrap': 'wrap',
					// 'max-width': '200px'
				},
				td: {
					'border-bottom': '1px solid #e9ecef',
					cursor: 'pointer',
				},
				footer: {
					border: 'none',
				},
			},
			language: {
				search: {
					placeholder: 'Busque aqui...',
				},
				pagination: {
					previous: '<',
					next: '>',
					to: '/',
					of: 'de',
					showing: 'Mostrando',
					results: () => 'Resultados',
				},
			},
		})
	}

	async function calculaSomaTotal(params) {
		const list = await getDadosTablePatrimonioCliente(
			`?${params}&paginate_by=99999`
		)

		let somaTotal = []

		for (let i = 0; i <= list.length - 1; i++) {
			let valores = []
			let soma = 0

			list?.forEach((item) => {
				if (item?.ativo_nome_valor[i]) {
					valores.push(item.ativo_nome_valor[i])
				}
			})

			valores.forEach((item) => {
				soma += Number(item)
			})

			if (soma !== 0) {
				somaTotal.push(soma)
			}
		}

		return somaTotal
	}

	return {
		createPatrimonioClienteTable,
		formatColumnsToTable,
		generateArrayDataForTable,
		getDadosTablePatrimonioCliente,
		onTableFetchServer,
		calculaSomaTotal,
	}
}
