import ModalResumo from '../components/ModalResumo'
import PdfModel from '../components/PdfModel'
import acessos from '../mixins/acessos'
import CardFundacao from '../components/CardFundacao'

const { FormatREALVirgula, api, FormatREAL } = GLOBAL

export default {
	mixins: [acessos],

	data() {
		return {
			atendimentos: [],
			haveModalResumo: true,
			todos_clientes: window.global_clientes?.todos_clientes,
		}
	},
	components: {
		ModalResumo,
		CardFundacao,
		PdfModel,
	},

	methods: {
		patrimonioFormated(patrimonio) {
			if (patrimonio == '-') return patrimonio
			if (patrimonio == '0') return '-'
			const value = FormatREALVirgula(patrimonio)
			return value
		},

		politicaFormated(politica) {
			console.log('poli', politica, typeof politica)

			const porc = parseFloat(politica.replace(',', '.')) * 100
			console.log('poli', politica, typeof politica, porc, typeof porc)
			return porc.toFixed(0)
		},

		getAtendimentos() {
			console.log('Hello darknees my old friend.')
			window.location.reload()
		},

		getAtendimentosQuentes() {
			console.log('Hello darknees my old friend.')
		},

		openModal({ currentTarget }) {
			const id = currentTarget.getAttribute('data-id')
			const politica = currentTarget.getAttribute('data-politica')
			this.$refs.modalResumo.OpenModalResumo({
				id,
				politica,
				atendimentoID: 0,
			})
		},

		sendNewProspect() {
			console.log('Hello darknees my old friend.')
		},

		openFeedback() {
			document.querySelector('.modal_padrao').classList.add('!min-h-max')
		},

		async handleFilter() {
			console.log('lidar com filtro')
		},
		/**
		 * Recarrega a página trazendo dados do usuario selecionado.
		 * Chamada no mounted.
		 */
		changeSelectFundacao() {
			const select = document.querySelector('[name=fundacao_alterna')
			const form = document.querySelector('form')

			select?.addEventListener('change', () => {
				form.submit()
			})
		},
		/**
		 * Seta valores dos inputs hidden a cada mudança no select.
		 * Os inputs serão usados para filtrar o intervalo escolhido.
		 * Chamada no mounted.
		 */
		changeSelectPatrimonio() {
			const select = document.querySelector('.patrimonio-select')

			select?.addEventListener('change', () => {
				const inputMin = document.querySelector('[name=patrimonio_min]')
				const inputMax = document.querySelector('[name=patrimonio_max]')
				const values = select?.value.split('-')

				inputMin.value = values[0] || ''
				inputMax.value = values[1] || ''
			})
		},
		/**
		 * Verifica valores dos filtros de patrimônio e seta opçao do select.
		 * Chamada no mounted
		 */
		filterPatrimonio() {
			const select = GLOBAL.getChoicesActive(
				'patrimonio-select',
				window.listOfChoices
			)

			if (!select) return
			const url = new URL(window.location)
			const filters = GLOBAL.splitFilters(url.search)

			if (!filters) return
			let arrFilter = Object.entries(filters)
			let str = ''

			arrFilter.forEach(([chave, valor]) => {
				if (chave == 'patrimonio_min' && valor != '') {
					str += `${valor}`
				}
				if (chave == 'patrimonio_max' && valor != '') {
					str += `-${valor}`
				}
			})
			console.log(arrFilter)
			GLOBAL.setSelectedOptionInChoicesDispatch(select, str)
		},

		/**
		 * Busca todas as fundações de determinado usuário. Chamada em clickChart()
		 * @param {string | number} id - id do usuário selecionado.
		 * @return {array} array de objetos das fundações separadas por categoria.
		 */
		async getDadosGraficoBolhas(id) {
			try {
				const { data: response } = await api.get(
					`/atendimento/grafico_bolhas_fundacoes/${id}`
				)

				return response
			} catch (err) {}
		},

		calculaIntervalo(valor) {
			// Os valores negativos ainda estão sendo considerados dentro do intervalo de zero, até saber o que fazer
			if (valor <= 500000000) {
				return 6
			} else if (valor > 500000000 && valor <= 1000000000) {
				return 10
			} else if (valor > 1000000000 && valor <= 5000000000) {
				return 14
			} else if (valor > 5000000000) {
				return 18
			}
		},

		zoomOut(dataLenght) {
			let inicio = 0
			let final = dataLenght
			document
				.querySelector('#chart-bubble')
				.addEventListener('wheel', (event) => {
					event.preventDefault()
					event.stopImmediatePropagation()

					if (event.wheelDeltaY > 0) {
						if (inicio > final) {
							let aux = inicio
							inicio = final
							final = aux
						} else {
							chartBubble.zoomX(inicio, final)
							inicio = inicio + 2 <= final ? inicio + 2 : final
							final = final - 2 >= 0 ? final - 2 : 0
						}
					} else {
						chartBubble.zoomX(inicio, final)
						inicio = inicio - 2 >= 0 ? inicio - 2 : 0
						final = final + 2 > dataLenght ? dataLenght : final + 2
					}
				})
		},

		fillCharts(data, chart, bubble) {
			let colors = ['#55A7FF', '#699CC9', '#437199', '#153047']

			if (chart == chartBubble) {
				let series = [
					{
						name: 'Clientes',
						data: [],
					},
				]
				if (data.length > 0) {
					//solução temporária até encontrar como colocar padding no inicio dos dados
					series[0].data.push({ name: '', x: '', y: 0, z: 0 })
					data.forEach((item, index) => {
						series[0].data.push({
							name: item.sigla,
							x: item.sigla,
							y: item.politica_atendida,
							z: this.calculaIntervalo(Number(item.patrimonio) * 1000),
							fillColor: 'red',
							patrimonio: item.patrimonio,
						})
					})
					//solução temporária até encontrar como colocar padding no fim dos dados
					series[0].data.push({ name: 'fim', x: 'fim', y: 0, z: 0 })
				}

				//alterando a cor conforme valor
				series[0].data.forEach((item) => {
					switch (item.z) {
						case 6:
							item.fillColor = colors[0]
							break
						case 10:
							item.fillColor = colors[1]
							break
						case 14:
							item.fillColor = colors[2]
							break

						case 18:
							item.fillColor = colors[3]
							break
						default:
							break
					}
				})
				const isVazio = series[0].data.length === 0 ? true : false
				const widthChart =
					series[0].data.length - 2 < 30
						? '100%'
						: `${(series[0].data.length - 2) * 40}px`
				console.log(widthChart)
				chart.updateOptions({
					series,
					chart: {
						//tamanho pra calculado com base em quantidade de clientes
						width: `${isVazio ? '100%' : widthChart}`,
					},
					title: {
						text: ` ${bubble}`,
					},

					tooltip: {
						enabled: true,
						x: {
							show: true,

							formatter: function (valor, { w }) {
								console.log(w.globals.categoryLabels[valor - 1])
								return w.globals.categoryLabels[valor - 1]
							},
						},
						y: {
							formatter: function (valor, { dataPointIndex }) {
								const patrimonio = GLOBAL.FormatREAL(
									series[0].data[dataPointIndex].patrimonio
								)
								return patrimonio || '0,00'
							},
							title: {
								formatter: (seriesName) => 'Patrimônio (Mil):',
							},
						},

						z: {
							formatter: function (valor, y) {
								/* Intervalos considerados
										0 - 500 milhões
										500 - 1 bi
										1 - 5 bi
										acima de 5 bi
									*/
								switch (valor) {
									case 6:
										return `entre R$ ${0} e R$ ${FormatREAL('500000000')}`
									case 10:
										return `entre R$ ${FormatREAL(
											'500000000'
										)} e R$ ${FormatREAL('1000000000')}`
									case 14:
										return `entre R$ ${FormatREAL(
											'1000000000'
										)} e R$ ${FormatREAL('5000000000')}`
									case 18:
										return `acima de R$ ${FormatREAL('5000000000')}`
								}
							},
							title: 'Intervalo:',
						},
					},
				})
			}
		},

		color(bubble) {
			switch (bubble) {
				case 'SAI/Cliente':
					return '#2945D3'
				case 'SAI':
					return '#4D82E9'

				case 'N/A':
					return '#6d6f71'

				case 'Consultor/Gestor':
					return '#079F69'
				default:
					return ''
			}
		},

		removeBorder(divs, bubble) {
			divs.forEach((item) => {
				if (item.getAttribute('bubble') != bubble) {
					item.classList.remove(
						`border-bubble-${item.getAttribute('bubble').split('/').join('-')}`
					)
				}
			})
		},

		positionArrow(svgs, bubble) {
			svgs.forEach((item) => {
				if (item.getAttribute('bubble') != bubble) {
					item.style.transform = 'rotate(0)'
					item.style.transition = '.2s ease opacity, .2s ease transform'
				}
			})
		},

		clickChart() {
			const divs = document.querySelectorAll('.card-bubble')
			const svgs = document.querySelectorAll('.arrow-bubble')
			const divChart = document.querySelector('#bubble')
			console.log(divs)
			divs.forEach((i) => {
				i.addEventListener('click', async () => {
					console.log(i)

					const bubble = i.getAttribute('bubble')
					const strBubble = bubble.split('/').join('-')
					//altera cor das bordas dos cards
					i.classList.toggle(`border-bubble-${strBubble}`)
					this.removeBorder(divs, bubble)
					this.positionArrow(svgs, bubble)

					//adiciona borda a div do gráfico
					divChart.style.borderColor = this.color(bubble)

					const svg = document.querySelector(`.arrow_click-${strBubble}`)
					if (i.classList.contains(`border-bubble-${strBubble}`)) {
						// terminando ainda
						let id
						const select = document.querySelector('[name=fundacao_alterna')

						const choice = GLOBAL.getChoicesActive(
							select.id,
							window.listOfChoices
						)
						const options = choice._presetChoices
						options.forEach((op) => {
							if (op.selected) {
								console.log(op)
								const aux = choice._presetOptions
								aux.forEach((i) => {
									if (i.value == op.value) {
										console.log(i)
										id = i.getAttribute('userId')
									}
								})
							}
						})
						console.log(choice)

						const data = await this.getDadosGraficoBolhas(id)
						this.fillCharts(data[bubble], chartBubble, bubble)
						this.zoomOut(data[bubble].length)

						divChart.classList.remove('invisible')
						svg.style.transform = 'rotate(90deg)'
						svg.style.transition = '.2s ease opacity, .2s ease transform'
					} else {
						divChart.classList.add('invisible')
						svg.style.transform = 'rotate(0)'
						svg.style.transition = '.2s ease opacity, .2s ease transform'
					}
				})
			})
		},
	},
	mounted() {
		setTimeout(() => {
			console.log('AQUI', this.todos_clientes)
			console.log('win', window['global_clientes'])
			this.filterPatrimonio()
			GLOBAL.verifyFilter('modelo_negocio', 'modelo_negocio')
		}, 1000)

		this.changeSelectFundacao()
		this.changeSelectPatrimonio()
		this.clickChart()
	},

	delimiters: ['{*', '*}'], //delimitadores pra n conflitar com o django
}
