import { capitalize } from 'lodash'

import CadastrarAtendimento from '../components/CadastrarAtendimento.js'
import UserDadosModal from '../components/UserDadosModal.js'
import UserEditDadosModal from '../components/UserEditDadosModal.js'
import AtendimentoItem from '../components/AtendimentoItem.js'
import NovoContato from '../components/NovoContato.js'
import AgendaPoliticasItem from '../components/AgendaPoliticasItem.js'
import NovaPolitica from '../components/NovaPolitica.js'
import TaskItem from '../components/TaskItem.js'
import NewTaskItem from '../components/NewTaskItem.js'
import ConfirmModal from '../components/ConfirmModal.js'
import badge from '../../modules/badge.js'
import AvatarPersona from '../components/AvatarPersona.js'
import acessos from '../mixins/acessos.js'
import atendimentos from '../mixins/atendimentos.js'
import divisaoInvestimentos from '../mixins/divisaoInvestimentos.js'

const {
	setValueInChoices,
	politicas,
	showToastify,
	initFlatpickr,
	api,
	changeColorModal,
	FormatREALBackEnd,
	FormatREAL,
	estadosBR,
	dispatchEventChange,
	addClassForTime,
	errorDefault,
	initTipy,
	setSelectedOptionInChoices,
	colorsChatAtendimento,
} = GLOBAL

let userLogado = document.getElementById('usuario-logado').value

NProgress.configure({ trickleSpeed: 40, trickle: true, minimum: 0.65 })

export default {
	template: document.getElementById('template-component-modal'),
	props: ['atendimentos', 'sendNewProspect'],
	mixins: [acessos, atendimentos, divisaoInvestimentos],
	emits: ['get-atendimentos', 'get-atendimentos-quentes', 'setId'],
	data() {
		return {
			below1660px: window.matchMedia('(max-width:1666px)').matches,
			chaves: [],
			rentabilidades: {
				rentabilidade_bm_ano: '-',
				rentabilidade_bm_12_meses: '-',
				rentabilidade_bm_inicio: '-',
				selected: 'cdi',
			},
			clientepf: [],
			modalConfirm: {},
			consultorias: [],
			options_status: [
				{value:'prospect',label: 'Prospect'},
      {value:'entrada_recorrente',label: 'Entrada Recorrente'},
      {value:'manutencao',label: 'Manutenção'},
      {value:'saida_recorrente',label: 'Saída Recorrente'},
			],
			novoAtendimentoOpen: false,
			tabInformacoesOpen: false,
			pontosContato: [],
			pontoContatoItem:{},
			tarefas: [],
			clienteAtivo: {
				tag: [],
			},
			clienteAtivoEditado: {
				tag: [],
			},
			atendimentoIDAtivo: null,
			atendimentoIDAtivoEdit: null,
			alterarSobre: false,
			atendimentosModal: [],
			atendimentosModalView: [],
			politicaAtendimentoSelect: [],
			atendimentoData: [],
			estados: '',
			adicionarContato: false,
			editor: {
				descricao: '',
				observacoes: ''
			},
			inputDate: '',
			agendaPoliticas: null,
			optionsAgendaPoliticas: [
				['Update', 'Update'],
				['Reunião', 'Reunião'],
			],
			novaPoliticaOpen: false,
			novaTaskOpen: false,
			modalConfirm: {},
			tags: [],
			atendimentosQuentes: [],
			bordaPura: false,
			openModal: false,
			widthLine: '',
			officers:'',
			usuariosResponsaveis: '',
			atendimentos_editar: [],
			periodo_ativo_liquidez: '', 
			ativosLiquidez: false,
			ativosRating: false,
			
		}
	},

	components: {
		CadastrarAtendimento,
		UserDadosModal,
		UserEditDadosModal,
		AtendimentoItem,
		NovoContato,
		AgendaPoliticasItem,
		NovaPolitica,
		TaskItem,
		NewTaskItem,
		ConfirmModal,
		AvatarPersona,
	},

	watch: {
		pontosContato: {
			//ordena por principal
			handler(newValue, oldValue) {
				if (newValue.length) {
					const novo = JSON.stringify(newValue?.map((i) => i.principal))
					const velho = JSON.stringify(oldValue?.map((i) => i.principal))
					if (novo !== velho) {
						return this.pontosContato
							.sort((x, y) => Number(x.principal) - Number(y.principal))
							.reverse()
					}
				}
			},
			deep: true,
		},

		clienteAtivo: {
			handler() {
				this.clienteAtivo.estado_sigla = this.setEstadoSigla(
					this.clienteAtivo.estado_id || this.clienteAtivo.estado
				)
			},
			deep: true,
		},

		agendaPoliticas: {
			handler() {
				if (this.agendaPoliticas) {
				
					this.agendaPoliticas = this.agendaPoliticas.sort(function (a, b) {
						return new Date(b.data) - new Date(a.data)
					})
				}
			},
			deep: true,
		},

		async 'clienteAtivo.tag'() {
			await this.getTags()

			if (window.choicesTag) window.choicesTag.destroy()

			const select = document.querySelector('#select-add-tag')
			const choices = GLOBAL.initChoices(select)
			const arrTagIds = this.clienteAtivo.tag?.map(({ id }) => id)
			window.choicesTag = choices

			const optionsFiltradas = this.tags
				.map((tag) => ({ value: tag.id, label: tag.titulo }))
				.map((tag) => ({
					...tag,
					disabled: arrTagIds.includes(tag.value),
				}))

			choices.setChoices([...optionsFiltradas], 'value', 'label', true)

			window.listOfChoices = [...window.listOfChoices, choices]
			badge()
		},
	},

	computed: {
		classBelow1660px() {
			return { below1660: this.below1660px }
		},

		patrimonioFormated() {
			const value = FormatREAL(
				this.isFundacao
					? this.clienteAtivo.patrimonio
					: this.clienteAtivo.patrimonio_externo
			)

			return value
		},
	},

	methods: {
		openModalSolitacao(id, tipoSolicitacao) {
			//const nameCurto = prospect.nome.split(' ')[0]
			if (tipoSolicitacao === 'delete') {
				
				this.modalConfirm = {
					open: true,
					text: `Deseja mesmo excluir esse atendimento? `,
					data: {id},
					id: 'solicitar_delete',
					botao: 'Solicitar exclusão',
					header: 'Excluir atendimento',
				}
			} else if (tipoSolicitacao === 'edicao') {
						
				this.modalConfirm = {
					open: true,
					text: `Deseja editar esse atendimento? `,
					data: {id},
					id: 'solicitar_editar',
					botao: 'Solicitar edição',
					header: 'Editar atendimento',
				}
			}else if (tipoSolicitacao === 'edicao_contato') {

				this.modalConfirm = {
					open: true,
					text: `Deseja editar esse contato? `,
					data: {id},
					id: 'solicitar_editar_contato',
					botao: 'Solicitar edição',
					header: 'Editar Ponto de Contato',

				}

 	}
		},
		isValidEditPontoContato(IdContato) {
			let status 
			
			if (this.status_edita_ponto_contato) {
				const _status = this.status_edita_ponto_contato.filter(statusEditaContato => statusEditaContato[1] === IdContato)[0] || []
				if (_status && _status?.length > 0) {
					status = _status[0]
				}

			}			
			if (status === 'Aprovado') return true
			return false
			
		},

		async editPontoContato(contato, event) {
			
			if (this.isFundacao) {
				contato.isEditEnabled = true
				this.editContatos(contato, event)
				return
			}
			const idContato = contato?.id
			if (this.isValidEditPontoContato(idContato)) {
				contato.isEditEnabled = true
				
				contato.isEditar = false
				this.editContatos(contato, event)
				
			} else {	
				contato.isEditar = true
				contato.isEditEnabled = false
				this.editContatos(contato, event)
			}
		}, 

		async sendSolicitacaoEdicaoContato() {

 			try {

			const id = this.modalConfirm.data?.id

			const responseSolicitarEdicao = await axios.get(`/clientes/solicitar_editar/${id}/`)

			if (responseSolicitarEdicao.data?.error) {

			throw new Error(responseSolicitarEdicao.data?.error)

			}

			GLOBAL.showToastify('✅ Solicitação enviada com sucesso')

			//await this.getDadosModal(this.clienteAtivo.id, true) 		 
			await this.getDadosUpdateModal()
			this.modalConfirm = {}

		} catch (e) {

				GLOBAL.showToastify( `❌ ${e.message || 'Erro ao solicitar edição do contato'}`)

				} finally {

				NProgress.done()

				}

		},

		async sendRequestAtendimento() {

			const idModal = this.modalConfirm.id
			
			if (idModal === 'solicitar_delete') {
				await this.sendRequestDeleteAtendimento()
			} else if (idModal === 'solicitar_editar') {
				await this.sendRequestEdicaoAtendimento()
			}
		},

		async sendRequestDeleteAtendimento() {
			try {
				const id = this.modalConfirm.data?.id
	
				const responseSolicitarDelete = await axios.delete(`/atendimento/solicitar_delete/${id}`)
				
				if (responseSolicitarDelete.data?.error) {
					throw new Error(responseSolicitarDelete.data?.error)
				}
				GLOBAL.showToastify('✅ Solicitação enviada com sucesso')
				await this.getDadosModal(this.clienteAtivo.id, true)		
				this.modalConfirm = {}
			} catch (e) {
					GLOBAL.showToastify( `❌ ${e.message || 'Erro ao solicitar exclusão'}`)
			} finally {
				NProgress.done()
			}
		},

		async sendRequestEdicaoAtendimento() {
			try {
				const id = this.modalConfirm.data?.id
		
				const responseSolicitarEditar = await axios.get(`/atendimento/solicitar_editar/${id}`)
				
				if (responseSolicitarEditar.data?.error) {
					throw new Error(responseSolicitarEditar.data?.error)
				}
				GLOBAL.showToastify('✅ Solicitação enviada com sucesso')
				await this.getDadosModal(this.clienteAtivo.id, true)		
				this.modalConfirm = {}
			} catch (e) {
					GLOBAL.showToastify( `❌ ${e.message || 'Erro ao solicitar edição'}`)
			} finally {
				NProgress.done()
			}
		},
		async OpenModalResumo({ id, atendimentoID }) {
			NProgress.start()

			const tab1 = document.querySelector(".tab-item[data-tab='tab-modal-1']")
			tab1 && tab1.click()
			this.usuariosResponsaveis = []
			await this.getDadosModal(id)
			await this.getChavesRentabilidades()
			this.updateSelectsModal()
			
			this.atendimentoIDAtivo = atendimentoID
			changeColorModal(this.clienteAtivo.politica, politicas)
			setTimeout(() => {
				modal_full.openModal('resumo-1')
				this.openModal = true
			}, 50)
			NProgress.done()
			initTipy()
			badge()
		},

		async modalOpenAtendimento({ id, atendimentoID }) {
			this.below1660px && this.toggleTabInformacoes(true)
			document.querySelector(".tab-item[data-tab='tab-modal-1']").click()
		
			await this.OpenModalResumo({ id, atendimentoID })

			setTimeout(() => {
				this.toggleNovoAtendimentoOpen(true)
				this.bordaPura = true
				setTimeout(() => {
					this.bordaPura = false
				}, 1500)
				setTimeout(
					() =>
						document.querySelector('.cadastrar [name="atendimento"]').focus(),
					300
				)
			}, 450)
		},

		updateSelectsModal() {
			// Dispara change para mudar valores

			setValueInChoices('cliente-modal-select', this.clienteAtivo.id) // seleciona cliente ativo
			setValueInChoices(
				'estado-modal-select',
				String(this.clienteAtivo.estado_id)
			)
			
	
			setValueInChoices('politica-modal-select', this.clienteAtivo.politica)

			//const principais = this.pontosContato.filter((i) => i.principal)
			// debugger
			if (this.usuariosResponsaveis?.length) {
				setValueInChoices(
					'responsavel-modal-select',
					this.usuariosResponsaveis.map((i) => i.id)
				)
			}
			dispatchEventChange('#estado-modal-select')
			dispatchEventChange('#cliente-modal-select')
			dispatchEventChange('#politica-modal-select')
			dispatchEventChange('#date-modal-input')
			dispatchEventChange('#responsavel-modal-select')
		},

		closeModal(e) {
			const btnPerfil = document.querySelector('[name=btn-tab-perfil]')
			const btnInvestimentos = document.querySelector(
				'[name=btn-tab-investimentos]'
			)
			modal_full.closeModal()
			this.toggleTabInformacoes(false)
			this.toggleNovoAtendimentoOpen(false)
			this.alterarSobre = false
			this.novaPoliticaOpen = false
			// this.agendaPoliticas = "";
			this.novaPoliticaOpen = false
			this.atendimentoIDAtivo = null
			this.infoGraficoModal = null
			this.isPerfil = false
			this.activeTab= 'investimentos'
			this.rendaFixa = false
			this.ativo_table = false
			this.ativosLiquidez = false
			this.ativosRating = false
			this.resetButtonsFilters()
			btnPerfil.classList.remove(...['active', 'secondary'])
			btnPerfil.classList.add('tertiary')
			btnInvestimentos.classList.add(...['active', 'secondary'])
			btnInvestimentos.classList.remove('tertiary')

			const accordionWrapper = document.querySelector('#atendimento-wrapper')
			if (accordionWrapper?.querySelector('.accordion-tittle.active')) {
				accordionWrapper.click()
			}

			this.emptyFields(
				this.atendimentoData,
				document.querySelector('#novoAtendimentoForm')
			)

			this.destroyAllCharts()

			if (this.pontosContato.length) {
				this.pontosContato = this.pontosContato.map((i) => {
					i.edicao = false
					return i
				})
			}
		},

		formatOfficersNames(officers) {
			return officers
				.map(({nome}) =>
					nome
						.split('.')
						.map((word) => capitalize(word))
						.join(' ')
				)
				.join(', ')
		},

		async getLimitesLiquidez(clienteID) {
			const url =`api/tabela_clientepf/detalhes_limites_liquidez/?cliente=${clienteID}`

			const responseLimiteLiquidez = await api.get(url)

			return responseLimiteLiquidez.data 
		},

		async getDadosModal(id, updateSameCliente) {
			this.modalConfirm = {}
			if (id !== this.clienteAtivo.id || updateSameCliente) {
				try {
					const url = this.isFundacao
						? `/atendimento/informacoes_modal_fundacao/?fundacao=${id}`
						: `/atendimento/informacoes_modal_cliente_pf/?cliente_pf=${id}`

					const request = await api.get(url)

					const {
						atendimentos,
						tarefas,
						clientes,
						contatos,
						grafico_carteira,
						status_exclusao,
						status_edicao,
						status_edita_ponto_contato,
						concentracoes,
						consultorias,
						tags,
						politica_atendida,
						fundacoes,
						tarefas_realizadas,
						bm_cdi,
						id_carteira,
						status_cliente,
						data_atual_carteira,
						total_eventos_de_credito,
						usuario_responsavel,
					} = await request.data

					
			
					const officerSpan = document.querySelector('.officer')

					if (officerSpan && usuario_responsavel?.length) {
						officerSpan.textContent = `Officers: ${this.formatOfficersNames(
							usuario_responsavel
						)}`
					}
				this.usuariosResponsaveis = usuario_responsavel
				
					this.setAtendimentosModal(atendimentos, usuario_responsavel, status_exclusao, status_edicao)
					this.setContatos(contatos, this.isFundacao ? fundacoes : clientes)
					this.setClientePF(this.isFundacao ? fundacoes : clientes)
					this.agendaPoliticas = []
					this.setAgendaPoliticas(atendimentos)
					this.total_eventos_de_credito = total_eventos_de_credito
					this.clienteAtivo = this.clientepf.filter((i) => i.id == id)[0]
					this.graficoCarteira = grafico_carteira
					this.margens = concentracoes
					this.consultorias = consultorias
					this.idCarteira = id_carteira
					this.dataAtualCarteira = data_atual_carteira
					this.atendimentos_editar = atendimentos
					this.status_edita_ponto_contato = status_edita_ponto_contato
				
					if(!this.isFundacao){
						this.setOptionSelectedStatus(status_cliente)

						this.setDates()
						this.ondisableAtualizarVolatilidade()
					}
					this.setTarefas(tarefas)
					this.clienteAtivo.politica_atendida = politica_atendida
					if (this.isFundacao) {
						this.clienteAtivo.tarefas_realizadas = `${
							tarefas_realizadas == '-' ? '-' : `${tarefas_realizadas}%`
						}`
					}
					this.setClienteTags(tags)

					if (!this.isFundacao) {
						if (bm_cdi.length) this.fillRentabilidades(bm_cdi[0])
						this.rentabilidades.selected = 'cdi'
						const choice = GLOBAL.getChoicesActive(
							'rentabilidade-modal-select',
							window.listOfChoices
						)
						setSelectedOptionInChoices(choice, '1')
					}
					this.updateSelectsModal()
					return request
				} catch (e) {
					errorDefault(e)
				}
			}
		},

		async getDadosUpdateModal() {
			await this.getDadosModal(this.clienteAtivo.id, true)
			changeColorModal(this.clienteAtivo.politica, politicas)
		},

		async getTags() {
			try {
				const end = this.isFundacao ? '?tag_sai=true' : '?tag_sap=true'
				const request = await api.get(`/api/tag/${end}/`)
				const data = await request.data

				this.tags = data?.results
			} catch (e) {
				errorDefault(e)
			}
		},

		async getRentabilidades(value) {
			try {
				const { data } = await axios.get(
					`/api/benchmark/?cliente_pf=${this.clienteAtivo.id}&chave=${value}`
				)

				if (!data.results.length) {
					this.rentabilidades.rentabilidade_bm_ano = '-'
					this.rentabilidades.rentabilidade_bm_12_meses = '-'
					this.rentabilidades.rentabilidade_bm_inicio = '-'
					return
				}
				this.fillRentabilidades(data.results[0])
			} catch (err) {
				errorDefault(err)
			}
		},

		setAgendaPoliticas(data) {
			
			this.agendaPoliticas = data
		},

		setAtendimentosModal(data,usuario_responsavel, exclusao_status, edicao_status) {
			
			this.setSelectPoliticas(data)
			this.atendimentosModal = data
			
			this.officers = this.formatOfficersNames(
				usuario_responsavel
			)
			const atendimentosModal = data.filter((i) => i.atendimento)
			if (this.isFundacao) {
					this.atendimentosModalView = atendimentosModal
			} else {
				const atendimentosModalViewWithStatusExclusaoAndEdicao = atendimentosModal.map(atendimento => {
					const status = exclusao_status.filter(status => status[1] === atendimento.id)
					const statusEdicao = edicao_status.filter(statusEdicao => statusEdicao[1] === atendimento.id)
					return {
						...atendimento,
						status_exclusao: status,
						status_edicao: statusEdicao
					}
				})
				this.atendimentosModalView = atendimentosModalViewWithStatusExclusaoAndEdicao
			}
			
		},

		setSelectPoliticas(data) {
		
			this.politicaAtendimentoSelect = [...data]
		
			const seDateOption = (i) => {
				const data = (v) =>
					`${moment(v.data, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${
						v.atendimento ? ' - Realizado' : ''
					}`
				
				return { value: i.id, label: data(i) }
			}

			const opts = this.politicaAtendimentoSelect.map(seDateOption)
			this.populateSelect('politica-modal-select', opts)
		},

		defineColors(labels) {
			const c = []
			labels?.forEach((label) => {
				const obj = GLOBAL.tipos_investimentos?.find((obj) => obj.tipo == label)

				if (obj) {
					c.push(obj.color)
				}
			})
			return c
		},

		async reloadModalData() {
			NProgress.start()
			await this.getDadosModal(this.clienteAtivo.id, true)

			this.setGraficoCarteira()
			this.setGraficoPerfilInvestidor()
			NProgress.done()
		},

		async getPerfilInvestidor(id) {
			try {
				const { data } = await api.get(`api/perfil_investidor/${id}/`)
				this.perfilInvestidor = data
			} catch (error) {
				console.log(error)
			}
		},

		toggleClass(el, names) {
			names.forEach((name) => {
				el.classList.toggle(name)
			})
		},

		handleClickBtnsChartsFilter() {
			const btns = document.querySelectorAll('.btn-chart-filter')

			btns.forEach((btn) => {
				btn.addEventListener('click', (e) => {
					GLOBAL.destroyApexChart([
						'chartAtivo',
						'chartEmissor',
						'chartTipo',
						'chartIndice',
						'chartTempoVencimentoIPCA',
						'chartTempoVencimentoPos',
						'chartTempoVencimentoPre',
					])

					let btnAtual = null

					btns.forEach((i) => {
						if (i.classList.contains('active')) {
							btnAtual = i
						}
					})
					if (btnAtual == btn) {
						return
					}
					if (!btn.classList.contains('active')) {
						this.toggleClass(btn, ['active', 'secondary', 'tertiary'])
					}
					const filterBtn = []

					btns.forEach((b) => {
						if (btn.id != b.id) {
							filterBtn.push(b)
						}
					})

					filterBtn.forEach((i) => {
						i.classList.remove('active')
						i.classList.remove('secondary')
						i.classList.add('tertiary')
					})
				})
			})
		},

		clickChartAtivo(nameSelected) {
			this.ativo_chart = true
			this.ativo_table = true
			this.emissor_chart = false

			this.tipo_chart = false
			this.indice_chart = false
			this.tempo_vencimento_chart = false
			this.ativosRating = false
			this.getInfoModalClienteTabInvestimentos(nameSelected)
		},

		async getDadosItensCarteira() {
			NProgress.start()
			try {
				const { data } = await axios.get(
					`${window.location.origin}/api/carteira/${this.idCarteira}`
				)

				data &&
					data.itens_carteira.forEach((i) => {
						if (i.tipo_investimento === 'Renda Fixa') {
							this.emissores = i.emissores
							this.tipos = i.tipos
							this.indices = i.indices
							this.tempo_vencimento = i.tempo_vencimento
						}
					})
			} catch (e) {
				errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		templateBodyTable(list_investimentos) {
			let template = ''
			list_investimentos?.forEach((item) => {
				template += `<tr>
				<td class="py-10" data-reference="coluna_1">
					<div class="flex gap-4 items-center ">
						<div class="cursor-pointer">
	
						</div>
	
						${item.tipo_investimento}
					</div>
	
				</td>
				<td class="py-10 centralizado" data-reference="coluna_2">
				
				${Number(item.pct_renda_fixa).toFixed(2)}
				</td>
				<td class="py-10 centralizado" data-reference="coluna_3">
				${Number(item.pct_global).toFixed(2)}
				</td>
				<td class="py-10 centralizado" data-reference="coluna_4">
					${GLOBAL.formatReal2(item.valor)}
				</td>
				<td class="py-10 centralizado" data-reference="coluna_5">
				
				${Number(item.taxa).toFixed(2)}
				</td>
				<td class="py-10 centralizado" data-reference="coluna_6">
				${item.vencimento}
				</td>
				<td class="py-10 centralizado" data-reference="coluna_7">
					${item.indexador}
				</td>
	
			</tr>`
			})
			return template
		},

		templateBodyTableOutrosInvestimentos(list_investimentos) {
			let template = ''
			list_investimentos?.forEach((item) => {
				template += `<tr>
				<td class="py-10" data-reference="coluna_1">
					<div class="flex gap-4 items-center ">
						<div class="cursor-pointer">
	
						</div>
	
						${item.tipo_investimento}
					</div>
	
				</td>
				<td class="py-10 centralizado" data-reference="coluna_2">
				
				${Number(item.pct_global).toFixed(2)}
				</td>
				<td class="py-10 centralizado" data-reference="coluna_3">
				${Number(item.pct_tipo_investimento).toFixed(2)}
				</td>
				<td class="py-10 centralizado" data-reference="coluna_4">
					${GLOBAL.formatReal2(item.valor)}
				</td>
				
	
			</tr>`
			})
			return template
		},

		async alternaVisualizacaoChartAtivo(e) {
			const el = e.target
			let classOutroBtn = '.table-btn'
			if (el.classList.contains('chart-btn')) {
				this.ativo_table = false
				classOutroBtn = '.table-btn'
			} else if (el.classList.contains('table-btn')) {
				this.ativo_table = true
				classOutroBtn = '.chart-btn'
			}

			if (!el.classList.contains('active')) {
				this.toggleClass(el, ['active', 'secondary', 'tertiary'])
				if (classOutroBtn) {
					this.toggleClass(document.querySelector(classOutroBtn), [
						'active',
						'secondary',
						'tertiary',
					])
				}
			}
		},

		async getDadosInvestimentosClientes(nameSelected) {
			try {
				NProgress.start()
				const url = `${window.location.origin}/atendimento/grafico_composicao_cliente_pf/?cliente_pf=${this.clienteAtivo.id}&tipo_investimento=${nameSelected}`
				const { data } = await axios.get(url)

				const investimentos = data?.composicao_investimentos
				const valores = Object.values(investimentos)
				return valores
			} catch (e) {
				errorDefault(e)
			} finally {
				NProgress.done()
			}
		},
		
		async getDadosInvestimentosClientesRendaFixaLiquidez() {
			try {
				NProgress.start()
				const url = `${window.location.origin}/atendimento/grafico_composicao_cliente_pf/?cliente_pf=${this.clienteAtivo.id}&tipo_investimento=Renda Fixa`
				const { data } = await axios.get(url)

				const investimentos = data
				//const valores = Object.values(investimentos)
				return investimentos
			} catch (e) {
				errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		async getDadosInvestimentosClientesRating(tipo, rating= '') {
			try {
				NProgress.start()
				const url = `${window.location.origin}/atendimento/grafico_composicao_cliente_pf/?cliente_pf=${this.clienteAtivo.id}&tipo_investimento=${tipo}&rating=${rating}`
				const { data } = await axios.get(url)

				const investimentos = data
				//const valores = Object.values(investimentos)
				return investimentos
			} catch (e) {
				errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		formatGraficoCarteiraItemToSort(item) {
			const itemSerialized = {
				...item,
				pct_global: Number(item.pct_global),
				pct_renda_fixa: Number(item.pct_renda_fixa),
				valor: Number(item.valor),
			}

			if (item.taxa) {
				itemSerialized.taxa = Number(item.taxa)
			}

			if (item.pct_renda_fixa) {
				itemSerialized.pct_renda_fixa = Number(item.pct_renda_fixa)
			}

			if (item.pct_tipo_investimento) {
				itemSerialized.pct_tipo_investimento = Number(
					item.pct_tipo_investimento
				)
			}

			if (item.vencimento) {
				itemSerialized.vencimento = GLOBAL.convertStringDateToDateInstance(
					item.vencimento
				).getTime()
			}

			return itemSerialized
		},

		onSortAtivosTable(orderByKey) {
			if (orderByKey === this.ativosTableSortKey) {
				this.ativosTableSortOrderInDesc = !this.ativosTableSortOrderInDesc
			}

			this.ativosTableSortKey = orderByKey

			const graficoCarteiraSerialized = this.graficoCarteira.map(
				this.formatGraficoCarteiraItemToSort
			)
			const graficoCarteiraSorted = GLOBAL.mergeSortAnArrayWithObjects(
				graficoCarteiraSerialized,
				orderByKey,
				this.ativosTableSortOrderInDesc
			)

			return graficoCarteiraSorted.map((item) => ({
				...item,
				vencimento: item.vencimento
					? GLOBAL.formatDateObject(new Date().setTime(item.vencimento))
					: '',
			}))
		},

		onSortAtivosTableRendaFixa(orderByKey) {
			let table = document.querySelector('#table_ativos')
			if (!table) {
				table =document.querySelector('#table_ativos_liquidez')
			}
			const bodyTable = table.querySelector('tbody')
			const ativosSorted = this.onSortAtivosTable(orderByKey)

			bodyTable.innerHTML = this.templateBodyTable(ativosSorted)
		},

		onSortAtivosTableOutrosInvestimentos(orderByKey) {
			const table = document.querySelector('#table_ativos')
			const bodyTable = table.querySelector('tbody')
			const ativosSorted = this.onSortAtivosTable(orderByKey)

			bodyTable.innerHTML =
				this.templateBodyTableOutrosInvestimentos(ativosSorted)
		},

		async clickItensInvestimentos(nameSelected) {
			this.ativo_chart = true
			this.ativo_table = true

			NProgress.start()
			try {
				const results = await this.getDadosInvestimentosClientes(nameSelected)
				const table = document.querySelector('#table_ativos')

				const bodyTable = table.querySelector('tbody')

				this.graficoCarteira = results?.map((i) => ({
					valor: i[0].valor,
					tipo_investimento: i[0].nome,
					pct_global: i[0].pct_global,
					pct_tipo_investimento: i[0].pct_tipo_investimento,
				}))
				bodyTable.innerHTML = this.templateBodyTableOutrosInvestimentos(
					this.graficoCarteira
				)
				const chartAtendimento = Apex._chartInstances?.filter(
					(i) => i.id == 'chartTabInvestimentos'
				)[0]
				chartAtendimento?.chart.destroy()
				GLOBAL.destroyApexChart(['chartTabInvestimentos'])
				this.setGraficoAtivo({
					name: nameSelected,
				})
			} catch (e) {
				errorDefault(e)
			} finally {
				NProgress.done()
			}
		},
		toggleClass(el, names) {
			names.forEach((name) => {
				el.classList.toggle(name)
			})
		},

		async getDadosItensCarteira() {
			NProgress.start()
			try {
				const { data } = await axios.get(
					`${window.location.origin}/api/carteira/${this.idCarteira}`
				)

				data &&
					data.itens_carteira.forEach((i) => {
						if (i.tipo_investimento === 'Renda Fixa') {
							this.emissores = i.emissores['todos']
							this.emissores_fgc = i.emissores['FGC']
							this.tipos = i.tipos['todos']
							this.tipos_fgc = i.tipos['FGC']
							this.indices = i.indices
							this.tempo_vencimento = i.tempo_vencimento
						}
					})
			} catch (e) {
				errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		async alternaVisualizacaoChartTipo(e) {
			const el = e.target
			let classOutroBtn = '.tipos_fgc'
			if (el.classList.contains('tipos_todos')) {
				classOutroBtn = '.tipos_fgc'
				await this.getInfoChartFiltro(
					'#chart-tipo',
					'chartTipo',
					this.tipos,
					'tipos',
					true
				)
			} else if (el.classList.contains('tipos_fgc')) {
				classOutroBtn = '.tipos_todos'
				//alterar pra nova variavel ainda
				await this.getInfoChartFiltro(
					'#chart-tipo',
					'chartTipo',
					this.tipos_fgc,
					'tipos'
				)
			}
			if (!el.classList.contains('active')) {
				this.toggleClass(el, ['active', 'secondary', 'tertiary'])
				if (classOutroBtn) {
					this.toggleClass(document.querySelector(classOutroBtn), [
						'active',
						'secondary',
						'tertiary',
					])
				}
			}
		},
		async onSelectChange(event) {
			this.clienteAtivoEditado = { ...this.clienteAtivoEditado, status_cliente: event?.target?.value || '' }
			await this.sendClienteAtivoEditado()
      
    },

		async alternaVisualizacaoChartEmissores(e) {
			const el = e.target
			let classOutroBtn = '.emissores_fgc'
			if (el.classList.contains('emissores_todos')) {
				classOutroBtn = '.emissores_fgc'
				await this.getInfoChartFiltro(
					'#chart-emissor',
					'chartEmissor',
					this.emissores,
					'emissores',
					true
				)
			} else if (el.classList.contains('emissores_fgc')) {
				classOutroBtn = '.emissores_todos'
				//alterar pra nova variavel ainda
				await this.getInfoChartFiltro(
					'#chart-emissor',
					'chartEmissor',
					this.emissores_fgc,
					'emissores'
				)
			}
			if (!el.classList.contains('active')) {
				this.toggleClass(el, ['active', 'secondary', 'tertiary'])
				if (classOutroBtn) {
					this.toggleClass(document.querySelector(classOutroBtn), [
						'active',
						'secondary',
						'tertiary',
					])
				}
			}
		},

		//Grafico por emissor
		async getInfoModalEmissor(nameSelected) {
			this.ativosRating = false
			this.rating_chart = false
			this.ativo_chart = false
			this.emissor_chart = true
			this.tipo_chart = false
			this.indice_chart = false
			this.tempo_vencimento_chart = false
			this.ativosLiquidez = false

			await this.getDadosItensCarteira()

			await this.getInfoChartFiltro(
				'#chart-emissor',
				'chartEmissor',
				this.emissores,
				'emissores',
				true
			)
		},

		orderTable() {
			this.graficoCarteira = this.graficoCarteira.reverse()
			const table = document.querySelector('#table_ativos')
			const bodyTable = table.querySelector('tbody')
			bodyTable.innerHTML = this.templateBodyTable(this.graficoCarteira)
		},

		setEstadoSigla(id) {
			const estado = estadosBR.filter((i) => i.id == id)
			return estado.length ? estado[0].sigla : ''
		},

		setPolitica(id) {
			const users = this.isFundacao ? politicas.fundacao : politicas.clientePF

			const politicasArr = Object.entries(users).map(([key, value]) => value)

			const final = politicasArr.filter((i) => id == i.id)
			return final.length ? final[0].name : null
		},

		setContatos(data) {
			this.pontosContato = data
			if (this.usuariosResponsaveis.length) {
				const selectOpts = this.usuariosResponsaveis?.map(({id,nome}) => ({
					value: id,
					label: nome,
				}))

				this.populateSelect('responsavel-modal-select', selectOpts)
				dispatchEventChange('#responsavel-modal-select')
			}
		},

		setAtendimentoData(data) {
			this.atendimentoData = data
		},

		setId(id){
			// setValueInChoices('cliente-modal-select', this.clienteAtivo.id) 
			
			this.setSelectPoliticas(this.atendimentos_editar)
			
			
			this.atendimentoIDAtivo = id
			setTimeout(() => {
				this.updateSelectsModal()
			}, 300);
		},

		//fetchs
		setClientePF(data) {
			this.clientepf = data
			this.populateSelectClientePF()
		},

		setClienteTags(data) {
			this.clienteAtivo.tag = data
			// setTimeout(() => {

			// }, 300);
		},

		async setToggleContatoPrincipal(contato) {
			try {
				const request = await axios.patch(`/api/contatos/${contato.id}/`, {
					principal: !contato.principal,
				})

				await this.getDadosModal(this.clienteAtivo.id, true)
				this.modalConfirm = {}
				const nomeCurto = contato.nome.split(' ')[0]
				showToastify(`✅ ${nomeCurto} Alterado(a) com sucesso.`)
			} catch (e) {
				errorDefault(e)
			}
		},

		async setAtendimentoQuente() {
			try {
				const url = this.isFundacao
					? `/api/fundacao/${this.clienteAtivo.id}/`
					: `/api/clientepf/${this.clienteAtivo.id}/`

				const request = await axios.patch(url, {
					atendimento_quente: true,
				})

				const nameCurto = this.isFundacao
					? this.clienteAtivo.sigla
					: request.data.nome.split(' ')[0]

				showToastify(`✅ ${nameCurto} agora é um atendimento quente.`)
				this.modalConfirm = {}
				await this.getDadosModal(this.clienteAtivo.id, true)
				// await this.getAtendimentosQuentes()
				this.$emit('get-atendimentos-quentes')
			} catch (e) {
				errorDefault(e)
			}
		},

		async setCancelAtendimentoQuente(data) {
			try {
				const url = this.isFundacao
					? `/api/fundacao/${data.id}/`
					: `/api/clientepf/${data.id}/`

				const request = await axios.patch(url, {
					atendimento_quente: false,
				})

				const nameCurto = this.isFundacao
					? this.clienteAtivo.sigla
					: request.data.nome.split(' ')[0]

				showToastify(
					`✅ ${nameCurto} foi removido(a) dos atendimentos quentes.`
				)
				this.modalConfirm = {}

				this.$emit('get-atendimentos-quentes')
				await this.getDadosModal(data.id, true)
			} catch (e) {
				errorDefault(e)
			}
		},

		async setTarefas(tasks) {
			this.tarefas = tasks.map((i) => ({
				...i,
				nome: this.isFundacao
					? this.clienteAtivo.sigla
					: this.clienteAtivo.nome,
				politica: this.clienteAtivo.politica,
			}))
		},

		async getChavesRentabilidades() {
			const response = await api.get('/api/benchmark/chaves/')

			const { data } = response

			this.chaves = Object.entries(data)

			this.setOptionsRentabilidades()
		},

		setOptionSelectedStatus(status) {
			const selectStatus = GLOBAL.getChoicesActive('status-modal-select', window.listOfChoices)
			
			if (status[0].status_cliente) {
				selectStatus?.setChoiceByValue(status[0].status_cliente)
			}
			
		},

		setOptionsRentabilidades() {
			if (this.chaves.length) {
				const selectOpts = this.chaves.map((item) => ({
					value: item[0],
					label: item[1],
				}))

				this.populateSelect('rentabilidade-modal-select', selectOpts)

				const selectRentabilidade = GLOBAL.getChoicesActive(
					'rentabilidade-modal-select',
					window.listOfChoices
				)
				const item = this.chaves[0]

				selectRentabilidade?.setChoiceByValue(item[0])
			}
		},

		async fillRentabilidades(rentabilidades) {
			if (
				this.clienteAtivo?.rentabilidade_ano <= 0 ||
				rentabilidades?.rentabilidade_bm_ano <= 0
			) {
				this.rentabilidades.rentabilidade_bm_ano = '-'
			} else {
				this.rentabilidades.rentabilidade_bm_ano =
					Number(
						(this.clienteAtivo?.rentabilidade_ano /
							rentabilidades?.rentabilidade_bm_ano) *
							100
					).toFixed(2) || '-'
			}

			if (
				this.clienteAtivo?.rentabilidade_12_meses <= 0 ||
				rentabilidades?.rentabilidade_bm_12_meses <= 0
			) {
				this.rentabilidades.rentabilidade_bm_12_meses = '-'
			} else {
				this.rentabilidades.rentabilidade_bm_12_meses =
					Number(
						(this.clienteAtivo.rentabilidade_12_meses /
							rentabilidades?.rentabilidade_bm_12_meses) *
							100
					).toFixed(2) || '-'
			}

			if (
				this.clienteAtivo?.rentabilidade_desde_inicio <= 0 ||
				rentabilidades?.rentabilidade_bm_inicio <= 0
			) {
				this.rentabilidades.rentabilidade_bm_inicio = '-'
			} else {
				this.rentabilidades.rentabilidade_bm_inicio =
					Number(
						(this.clienteAtivo.rentabilidade_desde_inicio /
							rentabilidades?.rentabilidade_bm_inicio) *
							100
					).toFixed(2) || '-'
			}
		},

		async sendEdicao({ id, index }) {
			try {
				await axios.patch(`/api/contatos/${id}/`, {
					...this.pontosContato[index], ...this.pontoContatoItem
				})

				this.pontosContato[index] = {...this.pontosContato[index], ...this.pontoContatoItem}
				this.pontosContato[index].editado = false
				
				this.editor.observacoes = ''
				this.editor.descricao = ''
				this.pontoContatoItem = {}
				showToastify('✅ Contato editado com sucesso.')
				this.setContatos(this.pontosContato)

				await axios.post(`${window.location.origin}/clientes/editado/${id}/`, this.pontosContato[index])
				await this.getDadosUpdateModal()
				initTipy()
			} catch (e) {
				errorDefault(e)
			}
		},

		async sendAdicionarContato(obj) {
			if (!obj.nome) {
				showToastify('⚠️ Preencha o nome antes de enviar')
				return
			}

			try {
				await axios.post(`/api/contatos/`, {
					...obj,
					[this.isFundacao ? 'fundacao' : 'cliente_pf']: this.clienteAtivo.id,
					principal: this.pontosContato.length >= 1 ? false : true,
				})

				await this.getDadosModal(this.clienteAtivo.id, true)
						
				tippy('[data-tippy-content]', {
					allowHTML: true,
					placement: "top",
					boundary: "HTMLElement"
				})
				document
					.querySelectorAll('.novo-atendimento p[contenteditable]')
					.forEach((i) => (i.innerText = ''))

				this.adicionarContato = false
				showToastify('✅ Contato Criado Com Sucesso.')
			} catch (e) {
				errorDefault(e)
			}
		},

		async sendAtendimento(dados) {
			const politicaSelected = dados.tipo === '0'
		
			try {
				NProgress.start()
				this.novoAtendimentoOpen = false
				const data_atendimento = moment(dados.data, 'DD/MM/YYYY').toDate()
				
				const obj = {
					...dados,
					usuario_responsavel: [...dados.contato_atendimento],
					data: data_atendimento,
					data_atendimento,
					
					
					concluido: true,
				}
				delete obj.contato_atendimento
				if (this.isFundacao) {
					delete obj.cliente_pf
					obj.fundacao = this.clienteAtivo.id
				}else{
					delete obj.modalidade_fundacao
				}

				delete obj.politica_atendimento

				let request
				if (politicaSelected) {
					if (dados.politica_atendimento == this.atendimentoIDAtivo) {
						obj.concluido = true
					}

					request = await axios.put(
						`${window.location.origin}/api/atendimentos/${dados.politica_atendimento}/`,
						obj
					)
					await axios.post(`${window.location.origin}/atendimento/editado/${dados.politica_atendimento}`, obj)
				} else {
					request = await axios.post(
						`${window.location.origin}/api/atendimentos/`,
						obj
					)
				}

				await this.getDadosModal(this.clienteAtivo.id, true)
				this.emptyFields(dados, document.querySelector('#novoAtendimentoForm'))

				showToastify('✅ Atendimento criado com sucesso.')

				const atendimentoWrapper = document.getElementById(
					'atendimento-wrapper'
				)
				const last = atendimentoWrapper.querySelector(
					`[data-accordion="acc-${await request.data.id}"]`
				)
			
				if (last && !last.parentElement.classList.contains('active')) {
					last.click()
				} else {
					last && last.click()
					setTimeout(() => {
						last && last.click()
					}, 150)
				}
				setTimeout(() => {
					last && last.scrollIntoView({ behavior: 'smooth', block: 'end' })
					addClassForTime(
						last?.closest('.item-borda'),
						'border-primary-pure',
						2000
					)
				}, 200)

				this.$emit('get-atendimentos')
			} catch (e) {
				errorDefault(e)
			} finally {
				NProgress.done()

				dispatchEventChange('#date-modal-input')
				dispatchEventChange('#novoAtendimentoForm')
			}
		},

		async getAtendimentosEmit() {
			await this.getDadosModal(this.clienteAtivo.id, true)		
		},

		async sendClienteAtivoEditado(message = true) {
			NProgress.start()

			try {
				const obj = {
					...this.clienteAtivoEditado,
					taxa: this.clienteAtivoEditado.taxa
						? Number(
								String(this.clienteAtivoEditado.taxa).replace(',', '.')
						  ).toFixed(2)
						: null,
					tag: this.clienteAtivoEditado.tag?.length
						? this.clienteAtivoEditado.tag.map((i) => i.id)
						: [],

					patrimonio_externo: this.clienteAtivoEditado.patrimonio_externo
						? FormatREALBackEnd(this.clienteAtivoEditado.patrimonio_externo)
						: 0,
					patrimonio_manual: this.clienteAtivoEditado.patrimonio_manual
						? FormatREALBackEnd(this.clienteAtivoEditado.patrimonio_manual)
						: 0,
					estado: Number(this.clienteAtivoEditado.estado_id),
				}
				const url = this.isFundacao
					? `/api/fundacao/${this.clienteAtivo.id}/`
					: `/api/clientepf/${this.clienteAtivo.id}/`

				const request = await axios.patch(url, obj)
				this.alterarInformacoesSobre(false)

				const nomeCurto = this.isFundacao
					? request.data.sigla
					: request.data.nome.split(' ')[0]

				const text = this.isFundacao
					? `✅ Fundaçao ${nomeCurto} alterado(a) com sucesso.`
					: `✅ Cliente ${nomeCurto} alterado(a) com sucesso.`

				message &&
					showToastify(text, {
						className: 'sucess',
						// node: nomee,
					})

				await this.getDadosUpdateModal()
				this.populateSelectClientePF()
				this.$emit('get-atendimentos')

				if (this.clienteAtivo.atendimento_quente) {
					this.$emit('get-atendimentos-quentes')
				}
			} catch (e) {
				this.clienteAtivoEditado = this.clienteAtivo
				errorDefault(e)
			}

			NProgress.done()
		},

		async sendNewTask(obj) {
			//COM ERRO
			NProgress.start()

			if (!obj.deadline || !obj.descricao) {
				showToastify('⚠️ Preencha Todos os campos')
				return
			}

			try {
				const cliente = {
					[this.isFundacao ? 'fundacao' : 'cliente_pf']: this.clienteAtivo.id,
				}

				const request2 = await axios.post(`/api/tarefas/`, {
					...obj,
					...cliente,
					deadline: moment(obj.deadline, 'DD/MM/YYYY').format('YYYY-MM-DD'),
					status: '1',
					usuario_responsavel: [userLogado],
				})

				this.novaTaskOpen = false

				if (this.clienteAtivo?.id) {
					await this.getDadosModal(this.clienteAtivo.id, true)
				}
				showToastify('✅ Tarefa Criada com sucesso.')

				return request2
			} catch (e) {
				this.novaTaskOpen = false
				errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		async sendAddTag(_, idTag = false) {
			const select = document.querySelector('#select-add-tag')
			const value = idTag ? idTag : select.value
			this.updateClienteAtivo(this.clienteAtivo)

			if (!value) {
				showToastify('⚠️ Selecione uma TAG para prosseguir')
				return
			}
			this.clienteAtivoEditado.tag.push({ id: value })

			try {
				await this.sendClienteAtivoEditado(true)
				modal_padrao.closeModal()
			} catch (e) {
				errorDefault(e)
			}
		},

		async sendNewTag() {
			const form = document.getElementById('form-create-tag')

			if (!form.checkValidity()) {
				showToastify('⚠️ Preencha todos os campos para criar uma TAG')
			} else {
				const fields = [...form.querySelectorAll('[name]')]
				const obj = fields.reduce((acc, { name, value }) => {
					acc[name] = value
					return acc
				}, {})

				if (this.isFundacao) {
					obj.tag_sai = true
				} else {
					obj.tag_sap = true
				}

				try {
					const request = await axios.post(`/api/tag/`, obj)
					const data = await request.data

					this.sendAddTag(false, await data.id)
					showToastify('✅ Tag criada e adicionada com sucesso.')
					modal_padrao.closeModal()
					await this.getDadosModal(this.clienteAtivo.id, true)
				} catch (e) {
					errorDefault(e)
				}
			}
		},

		openModalAtendimentoQuente() {
			const nameCurto = this.isFundacao
				? this.clienteAtivo.sigla
				: this.clienteAtivo.nome.split(' ')[0]

			this.modalConfirm = {
				open: true,
				text: `Deseja converter <b class="text-primary-pure">${nameCurto}</b> em um atendimento quente`,
				data: this.clienteAtivo,
				id: 'quente',
				class: 'fire',
			}
		},

		openModalCancelAtendimentoQuente(item) {
			const nameCurto = this.isFundacao ? item.sigla : item.nome.split(' ')[0]

			this.modalConfirm = {
				open: true,
				text: `Deseja remover <b class="text-primary-pure">${nameCurto}</b> dos atendimentos quentes`,
				data: item,
				id: 'cancel-quente',
				class: 'remove',
				botao: 'Remover',
			}
		},

		openModalRemoveTag(tag) {
			const nameCurto = this.isFundacao
				? this.clienteAtivo.sigla
				: this.clienteAtivo.nome.split(' ')[0]

			this.modalConfirm = {
				open: true,
				text: `Deseja remover <b class="text-primary-pure">${tag.titulo}</b> do(a) cliente ${nameCurto}`,
				data: tag,
				id: 'remove-tag',
				class: 'remove',
				botao: 'Remover',
			}
		},

		openModalPrincipalStar(contato) {
			const nameCurto = contato.nome.split(' ')[0]
			const text = contato.principal
				? `Deseja remover <b class="text-primary-pure">${nameCurto}</b> dos contatos principais`
				: `Deseja tornar <b class="text-primary-pure">${nameCurto}</b> o contato  principal`

			this.modalConfirm = {
				open: true,
				text: text,
				data: contato,
				id: 'principal-star',
				class: contato.principal ? 'remove' : '',
			}
		},

		//------------------------------------------------------------

		async removeTag(tag) {
			this.updateClienteAtivo(this.clienteAtivo)
			const arrTagRemovida = this.clienteAtivoEditado.tag.filter(
				(i) => i.id != tag.id
			)
			this.clienteAtivoEditado.tag = arrTagRemovida

			try {
				this.modalConfirm = {}
				await this.sendClienteAtivoEditado(false)
				showToastify('✅ Tag Removida com sucesso.')
			} catch (e) {
				errorDefault(e)
			}
		},

		colorTag(cor) {
			return {
				'--color': cor,
			}
		},

		toggleTabInformacoes(status) {
			this.tabInformacoesOpen = status
	_BIG({status})
		},

		toggleNovoAtendimentoOpen(status) {
			this.novoAtendimentoOpen = status
	
			
			this.updateSelectsModal()
		},

		formatMoeda(value) {
			return Number(value).toLocaleString('pt-BR')
		},

		arrExample(n) {
			return Array(n).fill(0)
		},

		alterarInformacoesSobre(value) {
			this.alterarSobre = value
		},

		populateSelect(selectID, opts) {
			
			const choices = GLOBAL.getChoicesActive(selectID, window.listOfChoices)
			choices?.clearStore()
			choices?.setChoices([...opts], 'value', 'label', true)
		},

		async sendTaskEditadaSucess(text) {
			// this.OpenModalSucesso(text)
			showToastify(text)
			await this.getDadosModal(this.clienteAtivo.id, true)
		},

		handleChangePontosContato({ event, id, index }) {
			const target = event.currentTarget
			const name = target.getAttribute('name')
			
			this.pontoContatoItem[name] = target.innerText
			this.pontosContato[index].editado = true
		},

		handleChangePontosContatoEditor({v, index,name }) {
		
			this.pontoContatoItem[name] = v
			this.pontosContato[index].editado = true
		},

		emptyFields(data, form) {
			Object.entries(data).map(([key, _]) => {
				const el = form.querySelector(`[name="${key}"]`)
				if (el) {
					if (el.tagName === 'SELECT') {
						const choices = GLOBAL.getChoicesActive(el.id, listOfChoices)
						choices && choices.removeActiveItems()
					} else {
						el.value = ''
					}
					el.dispatchEvent(
						new Event('change', { bubbles: true, cancelable: true })
					)
				}
			})
			form.dispatchEvent(
				new Event('change', { bubbles: true, cancelable: true })
			)
			form
				.querySelectorAll('.required-field')
				.forEach((i) => i.classList.remove('required-field'))
		},

		populateSelectClientePF() {
			const selectOpts = this.clientepf.map(({ id, nome }) => ({
				value: id,
				label: nome,
			}))
			this.populateSelect('cliente-modal-select', selectOpts)
			// DEIXAR SELECIONADO O CLIENTE ATIVO
			const selectCliente = GLOBAL.getChoicesActive(
				'cliente-modal-select',
				listOfChoices
			)
			selectCliente.setChoiceByValue(this.clienteAtivo.id)
		},

		politicasArr() {
			const politicas1 = this.isFundacao
				? politicas.fundacao
				: politicas.clientePF
			const politicasArr = Object.entries(politicas1).map(
				([key, value]) => value
			)

			return politicasArr.filter((politica) => politica.name !== 'AI')
		},

		updateClienteAtivo(data) {
			this.clienteAtivoEditado = data
		},

		async cancelarEdicao({ event, id, index }) {
			const url = this.isFundacao
				? `/api/contatos/?fundacao=${this.clienteAtivo.id}`
				: `/api/contatos/?cliente_pf=${this.clienteAtivo.id}`

			const request = await api.get(url)
			const data = await request.data
			this.editor.observacoes = ''
			this.editor.descricao = ''
			this.setContatos(await data?.results)
			if(this.pontosContato[index]){

				this.pontosContato[index].edicao = false
			}
		},

		getLength(item) {
			return item?.length ? item.length : null
		},

		openModalTag() {
			modal_padrao.openModal('incluir-tag')
		},

		generateRange2: (totalElementos, valor) => {
			return Array(totalElementos).fill(valor)
		},

		handleOpenModalConfirm(data) {
			const nameCurto = data.nome_cliente_pf.split(' ')[0]
			this.modalConfirm = {
				open: true,
				text: `Deseja tornar ${nameCurto} um atendimento quente`,
				data: data,
			}
		},

		debounceFunction: (fn, timing, wait = 200) => {
			return (...args) => {
				clearTimeout(timing)
				timing = setTimeout(() => fn(...args), wait)
			}
		},

		//!!! Retabilidades
		setRentabilidades() {
			const {
				rentabilidade_ano,
				rentabilidade_12_meses,
				rentabilidade_desde_inicio,

				rentabilidade_bm_ano,
				rentabilidade_bm_12_meses,
				rentabilidade_bm_inicio,
			} = this.clienteAtivo

			this.clienteAtivo.rentabilidade_ano = parseFloat(
				Number(rentabilidade_ano).toFixed(2)
			)
			this.clienteAtivo.rentabilidade_12_meses = parseFloat(
				Number(rentabilidade_12_meses).toFixed(2)
			)
			this.clienteAtivo.rentabilidade_desde_inicio = parseFloat(
				Number(rentabilidade_desde_inicio).toFixed(2)
			)

			this.clienteAtivo.rentabilidade_relativa_ano =
				rentabilidade_bm_ano && rentabilidade_ano
					? parseFloat(
							(
								(Number(rentabilidade_ano) / Number(rentabilidade_bm_ano)) *
								100
							).toFixed(2)
					  )
					: '-'

			this.clienteAtivo.rentabilidade_relativa_12_meses =
				rentabilidade_bm_12_meses && rentabilidade_12_meses
					? parseFloat(
							(
								(Number(rentabilidade_12_meses) /
									Number(rentabilidade_bm_12_meses)) *
								100
							).toFixed(2)
					  )
					: '-'

			this.clienteAtivo.rentabilidade_relativa_inicio =
				rentabilidade_bm_inicio && rentabilidade_desde_inicio
					? parseFloat(
							(
								(Number(rentabilidade_desde_inicio) /
									Number(rentabilidade_bm_inicio)) *
								100
							).toFixed(2)
					  )
					: '-'
		},

		editContatos(contato, { target }) {
			
			this.pontosContato = this.pontosContato.map((i) => {
				i.edicao = false
				return i
			})
			contato.edicao = true
			console.log(this.pontosContato, 'pontos')
			this.editor.descricao = contato.descricao
			this.editor.observacoes = contato.observacoes
			const el = target.closest('.ponto-contato-el')
			el && setTimeout(() => el.scrollIntoView({ block: 'center' }), 100)
		},
		openAddTag() {
			modal_padrao.openModal('criar_tag')
		},

		async handleChangeRentabilidade(event) {
			const value = event.target.value
			this.rentabilidades.selected = event.target.innerText
			await this.getRentabilidades(value)
		},
	}, //fim methods

	async created() {
		initTipy()
	},

	async mounted() {
		await this.getChavesRentabilidades()

		const inputDate = document.querySelector('#date-modal-input')

		if (inputDate) this.inputDate = initFlatpickr(inputDate)

		const self = this

		window.addEventListener(
			'resize',
			() => (self.below1660px = window.matchMedia('(max-width:1666px)').matches)
		)

		this.handleClickBtnsChartsFilter()
	},

	delimiters: ['{*', '*}'], //delimitadores pra n conflitar com o django
}
