import AvatarPersona from './AvatarPersona.js'

const { tipoSistema } = window
const { isFundacao, initTipy } = GLOBAL
export default {
	props: ['data'],
	emits: [
		'open-modal',
		'open-modal-with-atendimento',
		'ordenate',
		'open-send-email',
	],
	data() {
		return {
			isFundacao: isFundacao(),
		}
	},

	components: {
		AvatarPersona,
	},

	methods: {
		openModal(id, politica, atendimentoID) {
			this.$emit('open-modal', { id, politica, atendimentoID })
		},

		openModalWithAtendimento(id, politica, atendimentoID) {
			this.$emit('open-modal-with-atendimento', {
				id,
				politica,
				atendimentoID,
			})
		},

		ordenate(event, key, dataKey, number) {
			this.$emit('ordenate', { event, key, dataKey, ...number })
		},
		// emitOpenModal(atendimento){
		// 	this.$emit("openConfirmModal", atendimento);

		// }
		canculateDiference(end) {
			if (end == 'Sem atendimentos') return '-'
			else return GLOBAL.calculateDateDiference(end)
		},

		handleOpenModal(atendimento, withAtendimento) {
			const clienteID = this.isFundacao
				? atendimento.fundacao
				: atendimento.cliente_pf
			const politica = this.isFundacao
				? atendimento.politica_fundacao
				: atendimento.politica_cliente
			if (withAtendimento) {
				this.openModalWithAtendimento(clienteID, politica, atendimento.id)
			} else {
				this.openModal(clienteID, politica, atendimento.id)
			}
		},

		ultimaData(atendimento) {
			return isFundacao
				? this.canculateDiference(atendimento.ultimo_atendimento_fundacao)
				: this.canculateDiference(atendimento.ultimo_atendimento_cliente_pf)
		},

		openSendEmail(id) {
			this.$emit('open-send-email', id)
		},
	},

	computed: {
		skeletonClass() {
			return { skeleton: this.data[0]?.placeholder }
		},
	},
	mounted() {
		console.log(this.data, 'atendimento')
	},

	template: html`
		<div
			class="table-container max-h-[29.15rem] overflow-y-auto flex-1"
			:class="skeletonClass">
			<table class="relative">
				<thead class="sticky top-0 z-[10] bg-white">
					<tr>
						<th class="pointer-events-none pr-0"></th>
						<th
							@click="(e) => ordenate(e, isFundacao ? 'nome_fundacao' : 'nome_cliente_pf', 'atendimentos')">
							<p>Nomes</p>
						</th>
						<th
							@click="(e) => ordenate(e, isFundacao ? 'ultimo_atendimento_fundacao' : 'ultimo_atendimento_cliente_pf', 'atendimentos', {number:true})">
							<p>
								Último <br />
								atendimento
							</p>
						</th>
						<th
							@click="(e) => ordenate(e, 'status', 'atendimentos', {number:false})">
							Status
						</th>
						<th class="pointer-events-none pr-0"></th>
						<th class="pointer-events-none pr-0"></th>
					</tr>
				</thead>

				<tbody>
					<tr v-for="(atendimento, id) in data" :key="atendimento.id">
						<td class="cursor-pointer" @click="handleOpenModal(atendimento)">
							<avatar-persona
								:type="(isFundacao ? atendimento.politica_fundacao : atendimento.politica_cliente)"></avatar-persona>
						</td>

						<td
							class="cursor-pointer tippy-el"
							@click="handleOpenModal(atendimento)"
							:data-tippy-content="atendimento.nome_fundacao">
							<div class="one-row">
								{{ isFundacao ? atendimento.sigla_fundacao :
								atendimento.nome_cliente_pf }}
							</div>
						</td>

						<td class="cursor-pointer" @click="handleOpenModal(atendimento)">
							<div class="">{{ ultimaData(atendimento) }}</div>
						</td>

						<td class="cursor-pointer" @click="handleOpenModal(atendimento)">
							<div class="label" :class="atendimento.status">
								{{ atendimento.status }}
							</div>
						</td>

						<!-- <td @click="emitOpenModal(atendimento)">
							<div class="cursor-pointer hover:scale-125 transition-transform inline-flex active:scale-110 justify-center w-full disabled">
								<svg class="h-32 w-32 border border-transparent rounded-lg hover:border-neutral-60 ">
									<use xlink:href="#icon_fogo"></use>
								</svg> 
							</div>
						</td> -->

						<td
							@click="openSendEmail(isFundacao ? atendimento.fundacao : atendimento.cliente_pf)"
							data-tippy-content="Enviar E-mail"
							class="tippy-el">
							<div
								class="cursor-pointer hover:scale-125 transition-transform inline-flex active:scale-110 justify-center w-full ">
								<svg
									class="h-32 w-32 border border-transparent rounded-lg hover:border-neutral-60 ">
									<use xlink:href="#icon_email"></use>
								</svg>
							</div>
						</td>
						<td
							@click="handleOpenModal(atendimento, true)"
							data-tippy-content="Criar atendimento"
							class="tippy-el">
							<div
								class="cursor-pointer hover:scale-125 transition-transform inline-flex active:scale-110 justify-center w-full ">
								<svg
									class="h-32 w-32 border border-transparent rounded-lg hover:border-neutral-60 ">
									<use xlink:href="#icon_check"></use>
								</svg>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	`,
}
